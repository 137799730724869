.timeline-container {
  display: flex;
  align-items: center;
  height: auto;
  padding: 0 27px 0 20px;
  position: relative;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
}
.timeline-label {
  position: absolute;
  width: 275%;
  text-align: center;
  top: 30px;
  font-size: 12px;
  color: #54555C; 
  transform: translateX(-50%);
  left: 50%;
  font-family: Roboto;
font-weight: 400;
line-height: 18.75px;
letter-spacing: 0.02em;
}
.green {
  background: linear-gradient(106.77deg, #88CC93 0%, #109125 100%);

}

.orange {
  background: linear-gradient(94.39deg, #F5B383 0%, #D26F26 100%);
}

.grey {
  background-color: #54555C;
}

.line {
  flex-grow: 1;
  height: 1px;
}

.line.green {
  background-color: #119927; /* Replace with your desired green color */
}

.line.orange {
  background-color: #EA6708; /* Replace with your desired orange color */
}
