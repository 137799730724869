/* Cards.css */

.cards-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
}

.card {
  background-color: #fff;
  border-radius: 10px;
  padding: 1em;
  position: relative;
  z-index: 88;
  border: 0.2px solid rgb(192, 209, 236)
}

.card::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: calc(200 / 281 * 100%); /* Aspect ratio padding */
  position: absolute;
  top: 0;
  left: 0;
}

.card-one {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Spaces out top and bottom contents */
  height: 100%; /* Ensuring card takes full height */
}
.card-three {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Spaces out top and bottom contents */
  height: 100%; /* Ensuring card takes full height */
}
.card-four {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Spaces out top and bottom contents */
  height: 100%; /* Ensuring card takes full height */
}

.top-container {
  display: flex;
  align-items: center;
  position: relative;
  gap: 4px;
  margin-bottom: 1.6rem;
}

.total-scans-icon {
  width: 19px;
  height: 19px;
}

.total-scans-label {
  font-family: Baumans;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
}

.bottom-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align the content to the left */
  position: relative; /* So that it's positioned above the pseudo-element */
}

.total-scans-number {
  font-family: Roboto;
  font-size: 3.7rem;
  font-weight: 200;
  line-height: 75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
}

.percentage-change-container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.percentage-change {
  color: #119927;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0.02em;
}

.arrow-image {
  width: 9px;
  height: 9px;
  transform: rotate(0deg);
}

.from-yesterday-label {
  color: #54555c;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}

@media (max-width: 768px) {
  .cards-container {
    grid-template-columns: 1fr;
  }
}


.card-three .percentage-change {
    color: red;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 0.02em;
  }
  
.card-four .percentage-change {
    color: red;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 0.02em;
  }
  
.card-three .arrow-image {
    width: 9px;
    height: 9px;
    transform: rotate(90deg);
  }
.card-four .arrow-image {
    width: 9px;
    height: 9px;
    transform: rotate(90deg);
  }