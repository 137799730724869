.usertable-search-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .usertable-search-box {
    position: relative;
    width: 50%;
  }
  
  .usertable-search-box input {
    width: 100%;
    padding: 10px 30px 10px 15px;
    border: 1px solid #6889f7;
    border-radius: 20px;
    font-size: 16px;
  }
  
  .usertable-search-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
  }
  
  .usertable-button-container {
    display: flex;
    gap: 10px;
  }
  
  .usertable-btn {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
    box-shadow: 4px 4px 16px 0px #ffffff33 inset, -4px -4px 16px 0px #00000040 inset;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    font-family: Roboto;
  }
  
  .usertable-btn-icon {
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }
  
  .user-manager-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 30px;
  }
  
  .user-manager-table td {
    border-bottom: 0.5px solid #0956CC;
    padding: 8px;
    text-align: left;
  }
  
  .user-manager-table td {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    line-height: 18.75px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #54555C;
    padding: 10px 0 10px 0;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  
  .user-manager-table th {
    border-top: 1px solid #0956CC;
    border-bottom: 1px solid #0956CC;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #0956CC;
    padding-top: 4px;
    padding-bottom: 4px;
    text-align: left;
  }
  
  .user-manager-table tr {
    width: 100%;
  }
  
  .user-manager-table-timestamp {
    color: #54555C;
    opacity: 0.6;
    margin-top: 3.5px;
  }
  
  .user-manager-table-items {
    padding: 3px;
  }
  
  .user-manager-expand-icon {
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .user-manager-expand-icon.rotated {
    transform: rotate(180deg);
  }
  
  .status-cell {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .spacer {
    height: 0;
    transition: height 0.3s ease;
    overflow: hidden;
    border-top: none; /* Remove the top border for the row containing the buttons and tenant table */
  }
  
  .expanded td {
    border-bottom: none;
  }
  
  .expanded + tr {
    border-top: 0.5px solid #0956CC; /* Add the top border for the row following the expanded section */
  }
  
  .brand-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 999999;
    animation: slideInFromRight 0.3s ease-in-out;
  }
  
  .tenant-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 999999;
    animation: slideInFromRight 0.3s ease-in-out;
  }
  
  .brand-modal {
    width: 45%;
    height: 100%;
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  .tenant-modal {
    width: 45%;
    height: 100%;
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  .brand-modal-close {
    color: red;
    border: 1px solid rgb(239, 52, 52);
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    font-family: Roboto;
    background-color: transparent;
    cursor: pointer;
  }
    
    @keyframes slideInFromRight {
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(0);
      }
    }
    
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  
    .brand-modal p {
      margin-top: 40px;
      font-family: Roboto;
      font-size: 22px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.02em;
      text-align: left;
    }
    
    .user-manager-form-group {
      margin-bottom: 20px;
    }
    
    .user-manager-form-group label {
      display: block;
      margin-bottom: 5px;
      color: #54555C;
      font-family: Roboto;
      font-size: 15px;
  
    }
    
    .user-manager-form-group input,
    .user-manager-form-group select {
      width: 100%;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    
    .user-manager-form-group > div {
      display: inline-block;
      width: 49%;
    }
    
    .user-manager-form-group > div:first-child {
      margin-right: 2%;
    }
    
    .user-manager-confirm-box {
      text-align: right;
    }
    
    .user-manager-confirm-box button {
      padding: 8px 16px;
      background-color: #0956cc;
      color: #fff;
      border: none;
      border-radius: 24px;
      cursor: pointer;
    }
    
    .user-manager-close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: #be2938;
      color: #fff;
      border: none;
      border-radius: 24px;
      cursor: pointer;
      padding: 5px;
    }
  