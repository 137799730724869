/* General overlay for the popup */
.aztec-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
  
/* Main popup content styling */
.aztec-popup-content {
    background: #fff;
    padding: 20px;
    width: 300px;
    max-width: 90%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
}

/* Form styling */
.aztec-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
}

.aztec-form label {
    font-size: 14px;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.aztec-form input {
    padding: 8px;
    margin-top: 5px;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 5px;
}

/* Download button styling */
.aztec-form button {
    margin-top: 15px;
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.aztec-form button:hover {
    background-color: #0056b3;
}

/* Close button styling */
.close-btn {
    margin-top: 10px;
    background-color: transparent;
    color: #666;
    font-size: 14px;
    border: none;
    cursor: pointer;
}

/* Styling for the checkbox row */
.aztec-form .checkbox-label {
    display: flex;
flex-direction: row;
    gap: 5px; /* Adjust spacing between checkbox and label text */
    font-size: 14px;
    color: #333;
    align-items: center;
}

.aztec-form .checkbox-label input {
    width: 20px;
}
