.change-password-container {
  display: flex;
  min-height: 100vh;
}

.left-side {
  flex-basis: 55%;
  height: 100vh;
  background-color: #e2eeff;
  clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);
  display: flex;
  justify-content: center;
  position: relative;
}

.logo-container {
  position: absolute;
  left: 40%;
  top: 47%;
  transform: translate(-50%, -50%);
}

.right-side {
  flex-basis: 40%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 46rem;
  height: auto;
}

.change-password-right-side {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.change-password-welcome {
  font-family: Roboto;
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
}
.change-password-top-texts {
  display: flex;
  flex-direction: column;
}

.change-password-arvocloud {
  font-family: Baumans;
  font-size: 48px;
  font-weight: 400;
  line-height: 57px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #0956cc;
}

.change-password-input-box-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
}

.box-change-password-input {
  height: 56px;
  width: 500px;
  border: 2px solid #9da1b8;
  border-radius: 12px;
  padding-left: 13px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #54555c;
}
.box-change-password-input::placeholder {
  color: #54555c;
  opacity: 0.58;
}

.box-change-password-input:-ms-input-placeholder {
  color: #54555c;
  opacity: 0.58;
}

.box-change-password-input::-ms-input-placeholder {
  color: #54555c;
  opacity: 0.58;
}

.password-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.icon-eye {
  position: absolute;
  right: 20px;
  cursor: pointer;
  width: 24px;
}

.forgot-password-text {
  width: 100%;
  margin-top: -3px;
  color: #54555c;
  cursor: pointer;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.02em;
  text-align: right;
  opacity: 0.7;
}

.change-password-button {
  background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
  box-shadow: 4px 4px 16px 0px #ffffff33 inset,
    -4px -4px 16px 0px #00000040 inset;
  width: 501px;
  height: 57px;
  padding: 16px;
  border-radius: 12px;
  opacity: 1;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-family: Roboto;
  margin-top: 18px;
}

.change-password-button-inactive {
  background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
  width: 501px;
  height: 57px;
  padding: 16px;
  border-radius: 12px;
  opacity: 0.5; /* Decrease the opacity */
  color: white;
  border: none;
  cursor: not-allowed; /* Change cursor to indicate inactivity */
  font-size: 18px;
  font-family: Roboto;
  margin-top: 18px;
  box-shadow: none; /* Remove box-shadow */
}

.text-btn-below {
  margin-top: 12px;
}

.having-trb {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.02em;
  margin-right: 5px;
  opacity: 0.57;
}
.contact-us {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #0956cc;
}

.tenant-container {
  display: flex;
  min-height: 100vh;
}

.tenants-right-side {
  flex-basis: 40%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 46rem;
  height: auto;
}

.tenant-right-container {
  display: flex;
  /* align-items: center;
    justify-content: center; */
  flex-direction: column;
}

.tenant-hello {
  font-family: Roboto;
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0.02em;
  color: #54555c;
}

.tenant-brand {
  font-family: Baumans;
  font-size: 48px;
  font-weight: 400;
  line-height: 57px;
  letter-spacing: 0.02em;
  color: #0956cc;
}
.tenant-top-texts {
  display: flex;
  flex-direction: column;
}

.tenant-custom-dropdown {
  position: relative;
  display: inline-block;
  margin-top: 90px;
}

.tenant-dropdown-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border: 2px solid #9da1b8;
  cursor: pointer;
  border-radius: 9px;
  width: 470px;
  height: 50px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.02em;
  color: #54555c;
  padding-left: 10px;
}

.tenant-dropdown-arrow {
  width: 18px;
  height: auto;
  margin-right: 8px;
}

.tenant-dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 470px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 9px;
}

.tenant-dropdown-item {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.tenant-dropdown-item:hover {
  background-color: #e9f1fc;
  border-radius: 9px;
}

.tenant-proceed-button {
  background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
  box-shadow: 4px 4px 16px 0px #ffffff33 inset,
    -4px -4px 16px 0px #00000040 inset;
  width: 470px;
  height: 50px;
  padding: 11px;
  border-radius: 12px;
  opacity: 1;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-family: Roboto;
  margin-top: 50px;
}

.tenant-text-btn-below {
  margin-top: 12px;
  display: flex;
  justify-content: center;
}

.having-trb {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.02em;
  margin-right: 5px;
  opacity: 0.57;
}
.contact-us {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #0956cc;
}

.contact-us {
  cursor: pointer;
}
