.chat-box {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 45%;
  background-color: white;
  animation: slide-in 0.3s ease-out;
  z-index: 9999;
  display: flex;
  flex-direction: column;
}

.support-chat-box-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status-dropdown {
  margin-left: auto;
  padding: 5px;
  font-size: 1em;
}

.chat-box-header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  background-color: #f2f2f2;
}

.close-button {
  color: rgb(248, 30, 30);
}

.close-button {
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.chat-box-content {
  padding: 20px;
}

@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  background-color: white;
}

.chat-message {
  display: flex;
  margin-bottom: 10px;
}

.sender-icon {
  width: 37px;
  height: 37px;
  background-color: #ccc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #fff;
  flex-shrink: 0; /* Added */
  margin-right: 10px;
}

.message-details {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 10px;
  display: flex; /* Added */
  flex-direction: column; /* Added */
  max-width: calc(100% - 60px); /* Added */
}

.sender-name {
  font-weight: bold;
}

.message-time {
  font-size: 12px;
  color: #888;
}

.message-text {
  margin-top: 5px;
  max-width: 100%; /* Added */
  word-break: break-word; /* Added */
}

.chat-input-form {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f2f2f2;
}


.support-message-details,
.support-admin-message-details {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 10px;
  max-width: calc(100% - 60px);
}


.chat-input {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin-right: 10px;
  font-size: 14px;
}

.send-chat-button {
  padding: 8px 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 45px;
  background-color: #007bff;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.send-chat-button img {
  width: 20px;
  height: 20px;
}

.send-button:hover {
  background-color: #0056b3;
}
.user-message {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  background-color: white;
  display: flex;
  justify-content: flex-end;
}

.brand-message {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  background-color: white;
  display: flex;
  justify-content: flex-start;
}

.chat-admin-icon {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #fff;
  margin-left: 10px;
  order: 2;
}

.admin-message-details {
  background-color: #e9f0ff;
  border-radius: 10px;
  padding: 10px;
  max-width: calc(100% - 60px);
  order: 1;
}


.support-chat-box-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status-dropdown {
  margin-left: auto;
  padding: 5px;
  font-size: 1em;
}

.closing-message {
  margin: 10px;
  padding: 10px;
  background-color: #ffefc2;
  border-left: 5px solid #ffcc00;
  font-size: 1em;
  color: #333;
}
