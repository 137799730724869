.counterfeit-report-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  h1 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .counterfeit-report-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffe5e5; /* Light red background */
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .counterfeit-report-table th,
  .counterfeit-report-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .counterfeit-report-table th {
    background-color: #f44336; /* Red header background */
    color: white;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .counterfeit-report-table tr:nth-child(even) {
    background-color: #ffe5e5; /* Alternating row color */
  }
  
  .counterfeit-report-table tr:nth-child(odd) {
    background-color: #fff;
  }
  
  .counterfeit-report-table tr:hover {
    background-color: #fddcdc; /* Hover effect */
  }
  
  p {
  
    color: #555;
  }
  