.region-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-top: 20px;
    border-radius: 20px;
    margin-bottom: 20px;
    overflow: hidden;
  }
  
  .region-content {
    width: 100%; 
    height: 600px;
    overflow: auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 30px;
    border-radius: 20px;
    position: relative;

    
  }
  