
.react-tabs__tab-list {
  display: flex;
  border: 1px solid #0956CC !important; 
  border-radius: 20px; 
  overflow: hidden;
  font-size: 15px;
text-align: center;
background: white !important;
box-shadow: 4px 4px 16px 0px #FFFFFF33 inset, 0px 4px 6px 0px #00000040 }

.react-tabs__tab {
  cursor: pointer;
  width: 190px;
  margin: 2px !important;
  margin-top: 1px !important;
  margin-bottom: 3px !important;
  font-family: Roboto;
font-weight: 700;
color: #0956CC;
}

.react-tabs__tab:last-child {
  border-right: none;
}

.react-tabs__tab--selected {
  background: linear-gradient(92.49deg, #0956CC 0%, #1A4E9F 100%) !important;
  color: #fff !important;
  box-shadow: 
    4px 4px 16px 0px #FFFFFF33 inset, 
    -4px -4px 16px 0px #00000040 inset, 
    0px 4px 4px 0px #00000040; 
    border-radius: 20px !important;
    outline: none !important;
    font-family: Roboto;
font-weight: 700;

  }

  .react-tabs__tab:focus:after{
    background: transparent !important;
  }
.popup {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  position: fixed;
  top: 0;
  left: 0;
 
  right: 0;
  bottom: 0;
  height:100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Shadow effect */
}

.popup button {
  display: block;
  margin-top: 10px;
  background-color: #ccc;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.popup button:hover {
  background-color: #ddd;
}

.productdetails {
  background-color: #e4edfa;
  overflow-y: auto;
  width: 100%;
  margin: 0;
  margin-top: 107px;
  height: 1111px;
  padding-left: 20px;
  padding-right: 20px;
  /* overflow-x: hidden; */
}
/* e2eeff */
.productdetails-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.productdetails-text {
  font-family: Baumans;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
}

.product-details-content {
  display: flex;
  justify-content: space-between;
}

.product-details-product-name {
  font-family: Roboto;
  font-size: 29px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
}
.product-details-product-info {
  font-family: Roboto;
  font-size: 17px;
  font-weight: 400;
  line-height: 23.44px;
  letter-spacing: 0.02em;
  text-align: left;
  margin: 9px;
  color: #54555c;
}
.product-details-container-info {
  width: 570px;
}
.product-details-container-price {
  margin-top: 60px;
}
.product-details-unitprice {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #54555c;
}

.product-details-price {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #54555c;
  margin-left: 5px;
}
.product-details-button-container{
  margin-top: 40px;
  display: flex;
  flex-direction: row;
}
.product-details-buttons-left-start {
  width: 142px;
  height: 34px;
  /* padding: 10px 20px; */
  border-radius: 24px;
  background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
  box-shadow: 4px 4px 16px 0px #ffffff33 inset,
    -4px -4px 16px 0px #00000040 inset;
  border: 0px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-details-buttons-end {
  width: 152px;
  height: 34px;
  /* padding: 10px 20px; */
  border-radius: 24px;
  background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
  box-shadow: 4px 4px 16px 0px #ffffff33 inset,
    -4px -4px 16px 0px #00000040 inset;
  border: 0px;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  color: white;
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit-delte {
  width: 76px;
  height: 34px;
  padding: 10px 20px;
  border-radius: 24px;
  background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
  box-shadow: 4px 4px 16px 0px #ffffff33 inset,
    -4px -4px 16px 0px #00000040 inset;
  border: 0px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-delte-btn{
  color: white;
    cursor: pointer;
    width: 76px;
  height: 34px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 24px;
    background: rgba(184, 12, 12, 1);
    box-shadow: 4px 4px 16px 0px rgba(255, 255, 255, 0.2) inset;
    box-shadow: -4px -4px 16px 0px rgba(0, 0, 0, 0.25) inset;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;

    text-align: left;
    align-self: flex-start;
}

.product-details-buttons-right-end {
  display: inline-flex; /* Use inline-flex for flexible width based on content */
  align-items: center;  /* Center the content vertically */
  justify-content: center; /* Center the content horizontally */
  height: 34px;
  margin: 1px;
  padding: 10px 20px;
  border-radius: 24px;
  background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
  box-shadow: 4px 4px 16px 0px #ffffff33 inset,
    -4px -4px 16px 0px #00000040 inset;
  border: 0px;
  margin-left: 10px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: center; /* Center the text horizontally */
  color: white;
  cursor: pointer;
  white-space: nowrap; /* Prevent word breaks */
}

.product-details-right {
  position: relative; /* This allows for absolute positioning inside */
  padding-top: 50px; /* Make room for the dots */
}

.edit-delete-button {
  position: absolute; 
  top: 0; 
  right: 0;
 
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10; 
  gap: 10px;
}

.dots{
  height: 20px;
  width: 20px;
}
.css{

  /* aspect-ratio: 3/2; */
  object-fit: contain;
  /* mix-blend-mode: color-burn; */
}

.product-images-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto auto;
  gap: 6px;
}

.grid-item img {
  width: 100%;
  height: auto;
  display: block;
  z-index: 888;
  border-radius: 8px;
}
/* Add this CSS to make the images square */
.product-images-grid .grid-item img {
}

.product-images-grid .grid-item img {
  max-width: 100%;
  height: auto;
}


/* Adjust the main image to maintain its aspect ratio */
.main-image img {
  aspect-ratio: 1/1; /* Assuming aspect ratio of 3:2 for main image */
}

.main-image {
  grid-column: 1 / -1;
  grid-row: 1;
  object-fit:contain ;
}

.main-image img {
  max-height: 300px;
  object-fit: cover;
  z-index: 8888888;
  /* mix-blend-mode: color-burn; */
}

.small-image {
  grid-column: span 1;
  grid-row: 2;
}

.small-image img {
  max-height: 90px;
  object-fit: cover;
  z-index: 888;
  /* mix-blend-mode: color-burn; */
}

.above-table-texts{
  display: flex;
  justify-content: space-between;
  width: 50%;
  flex-direction: row;
  align-items: center;
}

.product-details-recent-scans{
  font-family: Baumans;
font-size: 20px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0.02em;
text-align: left;
color: #54555C;
}

.product-details-last-updated{
font-family: Roboto;
font-size: 14px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0.02em;
color: #54555C;
margin-right: 6px;
}
.product-details-reload-img{
  margin-right: 6px;
}

.print-button {
  display: flex;
  align-items: center;
  background-color: inherit;
  padding: 5px 12px;
  cursor: pointer;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #0956CC;
  border-radius: 30px;
}

.button-print-img {
  margin-left: 6px; /* Space between the text and the image */
  width: 14px; /* Adjust width as needed */
  height: 14px; /* Adjust height as needed */
}
.product-details-text-right{
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-details-table {
  width: 50%;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: fixed; /* Ensures that the columns are evenly distributed */
}

.product-details-table thead th {
  background-color: inherit; /* Light grey background for the header */
  padding: 3px; /* Provides space within the cells */
  text-align: left; /* Aligns text to the left */
  border: none;
  border-top: 1px solid #0956CC; /* Blue top border */
  border-bottom: 1px solid #0956CC; /* Blue bottom border */
  color: #0956CC;
  font-weight: 400;
  font-size: 16px;
}

/* Styles for tbody to remove borders */
.product-details-table tbody tr td {
  border: none;
  padding: 8px; 
  text-align: left;
  background-color: #e4edfa;
  font-size: 13px;
  color: #54555C;
}

.table-and-map {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align items at the top */
  margin-top: 10px;
  margin-bottom: 30px;
}


.table-container {
  flex: 1;
}

.product-details-map-container {
  flex: 1;
  display: flex;
  justify-content: center; /* Center the map in the container */
  align-items: center; /* Center the map vertically */
}

.table-and-map img {
  max-width: 100%; 
  max-height: 350px; 
  position: relative;
  left: 40px;
}

.product-details-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: fixed;
}



.product-details-analytical-info {
  margin-top: 30px;
}

.aztec-datamatrix-btn{
  width: 152px;
  height: 34px;
  border-radius: 24px;
  background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
  box-shadow: 4px 4px 16px 0px #ffffff33 inset,
    -4px -4px 16px 0px #00000040 inset;
  border: 0px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  color: white;
  cursor: pointer;
  margin-left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qr-code-section{
  flex-direction: column;
}