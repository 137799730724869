.terms-and-condition {
  background-color: #f2f7ff;
  overflow-y: auto;
  width: 100%;
  margin: 0;
  margin-top: 107px;
  height: 1111px;
  padding-left: 20px;
  padding-right: 20px;
}

.terms-header span {
  font-family: Baumans;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.terms-content{
    width: 80%;
}
.terms-content h2 {
  font-size: 20px;
  margin-top: 20px;
  font-family: Roboto;
}

.terms-content hr {
  border: none;
  height: 1px;
  background-color: #ccc;
  margin-bottom: 20px;
}

.terms-content p,
.terms-content ul {
  margin-bottom: 20px;
  font-family: Roboto;
  font-size: 16px;
}

.terms-content ul {
  list-style-type: disc;
  padding-left: 20px;
}

.terms-content a {
  color: #007bff;
  text-decoration: none;
}

.terms-content a:hover {
  text-decoration: underline;
}
