.orders {
  background-color: #F2F7FF;
  overflow-y: auto;
  width: 100%;
  margin: 0;
  margin-top: 107px;
  height: 100vh;
  padding-left: 20px;
  padding-right: 20px;
}

.orders-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orders-text {
  font-family: Baumans;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
}

.order-filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: 85px;
  height: 28px;
  padding: 6px 10px;
  border-radius: 24px;
  border: 1px solid #0956CC;
  opacity: 1;
  background-color: transparent;
  color: #0956CC;
  font-size: 14px;
  cursor: pointer;
}

.order-filter-button img {
  width: 13px; 
  height: 13px;
}


.orders-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
}

 .orders-table td {
  border-bottom: 0.5px solid #0956CC;
  padding: 8px;
  text-align: left;
}

.orders-table td{
font-family: Roboto;
font-size: 15px;
font-weight: 400;
line-height: 18.75px;
letter-spacing: 0.02em;
text-align: left;
color: #54555C;
padding: 10px 0 10px 0;
padding-top: 11.5px;
padding-bottom: 11.5px;
}
.orders-table th{
border-top:  1px solid #0956CC;
border-bottom:  1px solid #0956CC;
font-family: Roboto;
font-size: 16px;
font-weight: 400;
line-height: 18.75px;
letter-spacing: 0.02em;
text-align: left;
color: #0956CC;
padding-top: 4px;
padding-bottom: 4px;
/* padding: 8px; */
  text-align: left;
}


.orders-table tr{
width: 100%;
/* border-top:  1px solid #0956CC; */
/* border-bottom: 1px solid #0956CC; */

}

.order-table-timestamp{
color: #54555C;
opacity: 0.6;
margin-top: 3.5px;
}

.order-table-items{
padding: 3px;
}

.order-expand-icon{
cursor: pointer;
transition: transform 0.3s ease;
}
.order-expand-icon.rotated {
  transform: rotate(180deg);
}
.status-cell {
display: flex;
justify-content: center;
align-items: center;
}

.spacer td {
  transition: height 0.3s ease;
  height: 0;
  background-color: transparent;
  overflow: hidden;
}

.expanded td {
  height: auto;
  padding: 0;
  background-color: transparent;
}

/* .expanded td {
  height: 200px;
  padding: 0;
} */

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(720deg);
  }
}

.reload-icon {
  cursor: pointer;
}

.rotate {
  animation: rotate 2s linear infinite; /* Apply the animation */
}
