.dashboard {
  background-color: #F2F7FF;
  overflow-y: auto;
  width: 100%;
  margin: 0;
  margin-top: 107px; 
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.firstpopupbtn {
  padding: 10px 20px;
  background: linear-gradient(92.49deg, #0956CC 0%, #1A4E9F 100%);
  box-shadow: 4px 4px 16px 0px #FFFFFF33 inset, -4px -4px 16px 0px #00000040 inset;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.animated-arrow {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.visible {
  display: block;
}

.hidden {
  display: none;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.bounce {
  animation: bounce 1s infinite;
}

.react-tabs__tab-list {
  display: flex;
  border: 1px solid #0956CC !important; 
  border-radius: 20px; 
  overflow: hidden;
  font-size: 15px;
  text-align: center;
}

.react-tabs__tab {
  cursor: pointer;
  background-color: transparent;
  width: 160px;
  margin: 2px !important;
  margin-top: 1px !important;
  margin-bottom: 3px !important;
  font-family: Roboto;
  font-weight: 700;
  color: #0956CC;
}

.react-tabs__tab:last-child {
  border-right: none;
}

.react-tabs__tab--selected {
  background: linear-gradient(92.49deg, #0956CC 0%, #1A4E9F 100%) !important;
  color: #fff !important;
  box-shadow: 4px 4px 16px 0px #FFFFFF33 inset, -4px -4px 16px 0px #00000040 inset, 0px 4px 4px 0px #00000040; 
  border-radius: 20px !important;
  outline: none !important;
  font-family: Roboto;
  font-weight: 700;
}

.react-tabs__tab:focus:after {
  background: transparent !important;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-text {
  font-family: Baumans;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
}

.last-updated {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
}

.update-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(92.49deg, #0956CC 0%, #1A4E9F 100%);
  box-shadow: 4px 4px 16px 0px #FFFFFF33 inset, -4px -4px 16px 0px #00000040 inset;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  border-radius: 24px;
  gap: 12px;
  font-family: Roboto;
  line-height: 16px;
  letter-spacing: 0.02em;
}

.right-side-content {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 30px;
}

.dailydropdown {
  position: relative;
  display: inline-block;
}

.dailydropdown-content {
  position: absolute;
  background-color: #f0f5fc;
  min-width: 116px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1000;
  right: 0;
  border-radius: 7px;
  text-align: center;
}

.dailydropdown-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  z-index: 10000;
}

.dailydropdown-item:hover {
  background-color: #E2EEFF;
}

.pie-graph-container {
  display: flex;
  gap: 15px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(720deg);
  }
}

.reload-icon {
  cursor: pointer;
}

.rotate {
  animation: rotate 2s linear infinite;
}
/* Media queries for tablets and mobile devices */
@media (max-width: 1024px) {
  .right-side-content {
    flex-direction: row; /* Change this to row to keep it in the same line */
    align-items: center; /* Center align items */
    gap: 10px;
  }

  .update-button {
    width: auto; /* Adjust width to auto */
  }

  .last-updated {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .dashboard {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 120px; /* Increase margin-top for better visibility */
  }

  .react-tabs__tab-list {
    display: none; /* Hide TabList on mobile screens */
  }

  .dashboard-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .dashboard-text {
    font-size: 18px;
    margin-top: 80px;
  }

  .last-updated {
    font-size: 12px;
  }
 
  .right-side-content {
    flex-direction: row; /* Change this to row to keep it in the same line */
    align-items: center; /* Center align items */
  }

  .update-button {
    width: auto; /* Adjust width to auto */
    font-size: 12px;
  }

  .reload-icon {
    width: 24px;
    height: 24px;
  }

  .pie-graph-container {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .dashboard {
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 130px; /* Further increase margin-top for better visibility */
  }

  .react-tabs__tab-list {
    display: none; /* Hide TabList on mobile screens */
  }
  .dashboard{
    margin-top: 300px;
  }
  .dashboard-header {
    flex-direction: column;
    align-items: center;
  }

  .dashboard-text {
    font-size: 16px;
  }

  .last-updated {
    font-size: 10px;
  }

  .right-side-content {
    flex-direction: row; /* Change this to row to keep it in the same line */
    align-items: center; /* Center align items */
    gap: 5px;
  }

  .update-button {
    width: auto; /* Adjust width to auto */
    font-size: 10px;
  }

  .reload-icon {
    width: 20px;
    height: 20px;
  }

  .pie-graph-container {
    flex-direction: column;
    align-items: center;
  }

  .pie-graph-container > div {
    width: 100%;
  }
}
