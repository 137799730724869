.support-chat-box {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 45%;
  background-color: white;
  animation: slide-in 0.3s ease-out;
  z-index: 999999999999999999999;
  display: flex;
  flex-direction: column;
}

.support-chat-box-header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  background-color: #f2f2f2;
}

.support-close-button {
  color: rgb(248, 30, 30);
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.support-chat-box-content {
  padding: 2px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background: linear-gradient(135deg, #3496ff, #4fb9ff);
}

@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.support-chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px 10px 10px 10px;
  background-color: white;
}

.support-chat-message,
.support-admin-message {
  display: flex;
  margin-bottom: 10px;
}

.support-chat-message {
  justify-content: flex-start;
  margin-right: auto;
}

.support-admin-message {
  justify-content: flex-end;
  margin-left: auto;
}

.support-sender-icon {
  width: 37px;
  height: 37px;
  background-color: #ccc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #fff;
  flex-shrink: 0;
  margin-right: 10px;
}

.support-chat-admin-icon {
  margin-left: 10px;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #fff;
}

.support-message-details,
.support-admin-message-details {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 10px;
  max-width: calc(100% - 60px);
}

.support-admin-message-details {
  background-color: #e9f0ff;
}

.support-sender-name {
  font-weight: bold;
}

.support-message-time {
  font-size: 12px;
  color: #888;
}

.support-message-text {
  margin-top: 5px;
  max-width: 100%;
  word-break: break-word;
}

.support-chat-input-form {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f2f2f2;
}

.support-chat-input {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin-right: 10px;
  font-size: 14px;
}

.support-send-chat-button {
  padding: 8px 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 45px;
  background-color: #007bff;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.support-send-chat-button img {
  width: 20px;
  height: 20px;
}

.support-send-button:hover {
  background-color: #0056b3;
}

.thankyou-message {
  padding: 10px;
  background-color: #ffefc2;
  border-left: 5px solid #ffcc00;
  font-size: 1em;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .support-chat-box {
    width: 80%;
  }

  .support-sender-icon,
  .support-chat-admin-icon {
    width: 30px;
    height: 30px;
  }

  .support-chat-input {
    font-size: 12px;
  }

  .support-send-chat-button {
    height: 35px;
  }

  .support-message-details,
  .support-admin-message-details {
    padding: 8px;
  }

  .support-chat-messages {
    padding: 10px 5px;
  }
}

@media (max-width: 480px) {
  .support-chat-box {
    width: 100%;
  }

  .support-sender-icon,
  .support-chat-admin-icon {
    width: 25px;
    height: 25px;
  }

  .support-chat-input {
    font-size: 10px;
  }

  .support-send-chat-button {
    height: 30px;
  }

  .support-message-details,
  .support-admin-message-details {
    padding: 6px;
  }

  .support-chat-messages {
    padding: 10px 5px;
  }
}
