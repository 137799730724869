.settings {
    background-color: #F2F7FF;
    overflow-y: auto;
    width: 100%;
    margin: 0;
    margin-top: 107px;
    height: 1111px;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .settings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Baumans;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-top: 20px;
  }
  
.settings-main-text{
    font-family: Baumans;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
  }

  .settings-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .settings-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .settings-section {
    margin-bottom: 40px;
  }
  
  .section-title {
    margin-bottom: 10px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
text-decoration: underline;
  }
  
  .options-list {
    list-style: none;
    padding: 0;
  }
  
  .options-list li {
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
  }
  
  .options-list li:last-child {
    border-bottom: none;
  }
  
  .options-list li:hover {
    background-color: #e0ebff;
  }
  