/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: scroll;
    z-index: 999999;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    width: 80%;
    height: 75%;
    max-height: 80vh;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative; /* Enable absolute positioning for the close button */
  }
  
  .close-button {
    position: absolute; /* Position the button in the top right corner */
    top: 10px;
    right: 10px;
    background: none; /* Remove default button background */
    border: none; /* Remove default button border */
    color: red; /* Red color for the "X" */
    font-size: 24px; /* Increase size for visibility */
    font-weight: bold; /* Make the button bold */
    cursor: pointer; /* Change cursor to pointer on hover */
  }
  .scrollingpopup{
    width: 100%;
    height: 80%;
    overflow-y: auto;
  }
  .close-button:hover {
    opacity: 0.7; /* Add hover effect */
  }
  
  button {
    margin-top: 20px;
  }
  