.company-search {
    position: relative;
    width: 100%;
  }
  
  .company-search-input {
    width: 100%;
   
    box-sizing: border-box;
  }
  
  .company-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    border-top: none;
    background: white;
    max-height: 200px; /* Adjust the height as needed */
    overflow-y: auto;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Ensure the dropdown is above other elements */
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .company-dropdown-item {
    padding: 10px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .company-dropdown-item:hover {
    background: #f0f0f0;
  }
  
  .error-message {
    color: red;
    margin-top: 5px;
  }
  