/* Container: Centered on the page */
.morepen-container {
  max-width: 600px; /* Adjust as needed */
  margin: 40px auto;
  padding: 24px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Title */
.morepen-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 24px;
  text-align: center;
  color: #2c3e50;
}

/* Form: Occupies full container width, with rows spaced out */
.morepen-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}

/* Each row: label + input on a single line */
.form-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 8px; /* Space between label & input */
}

/* Labels: Fixed width for consistent alignment */
.form-label {
  display: inline-block;
  width: 120px; /* Fixed width to align all labels */
  font-size: 1rem;
  font-weight: 700;
  color: #4a4a4a;
  margin: 0;
  text-align: left;
}

/* Inputs: Grow or shrink to fill remaining space */
.form-input {
  flex: 1;
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.875rem;
  color: #333;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  max-width: 300px; /* Prevents input from becoming too wide */
}

/* Make date input larger (optional) */
.form-input[type="date"] {
  padding: 12px 14px;
  font-size: 1rem;
}

/* Focus state for inputs */
.form-input:focus {
  outline: none;
  border-color: #007BFF;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.2);
}

/* Separate row just for the button, centered */
.form-group-button {
  display: flex;
  justify-content: center; /* Centers the button horizontally */
  width: 100%;
}

/* Submit Button */
.submit-button {
  padding: 10px 16px;
  background-color: #1a73e8;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.1s ease;
  max-width: 300px; /* Matches input width for visual consistency */
  width: 100%;
}

/* Button Hover & Active States */
.submit-button:hover:not(:disabled) {
  background-color: #185abc;
}

.submit-button:active:not(:disabled) {
  transform: scale(0.98);
}

.submit-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
