.slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .selected-image {
    max-width: 600px; /* Adjust width as needed */
    max-height: 400px; /* Adjust height as needed */
  }
  
  .selected-image-img {
    max-width: 100%;
    min-width: 250px;
    height: auto;
    border-radius: 10px;
    overflow: auto;
  }
  
  .image-options {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .image-option {
    margin-right: 10px;
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .image-option-img {
    width: 100px; /* Adjust width as needed */
    height: 100px; /* Adjust height as needed */
    border: 0.5px solid #000; /* Add border */
    border-radius: 10px; /* Add rounded corners */
   margin-left: 10px;
  }
  
  .selected .image-option-img {
    filter: blur(5px); /* Add blur effect to selected image */
  }
  
  .selected .image-option-img.selected-img {
    filter: none; /* Remove blur effect for the selected image */
  }
  
  .image-option:hover {
    transform: scale(1.1);
  }
  