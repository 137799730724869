.table-container {
  margin: 10px 0;
  background-color: white;
  border-radius: 20px;
  padding: 10px 20px 20px 20px;
}

.data-table {
  overflow: auto;
  width: 100%;
  border-collapse: collapse;
}

.data-table th,
.data-table td {
  text-align: center;
  padding: 16px;
  width: 20%;
  border: none;
}

.data-table th {
  background-color: transparent;
  border-top: 1px solid #0956cc;
  border-bottom: 1px solid #0956cc;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.41px;
  letter-spacing: 0.02em;
  color: #0956cc;
  padding-top: 10px;
  padding-bottom: 10px;
}

.data-table tbody tr:hover {
  background-color: rgb(243, 249, 255);
}

.table-body {
  background-color: transparent;
}

.table-header {
  color: #0956cc;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.metrics-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.download-btn {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 24px;
  background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
  box-shadow: 4px 4px 16px 0px #ffffff33 inset, -4px -4px 16px 0px #00000040 inset;
  border: none;
  color: white;
  cursor: pointer;
}

.download-btn img {
  margin-left: 5px;
  height: 15px;
}

.data-table td {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  letter-spacing: 0.02em;
  color: #54555c;
}

.table-row:hover .row-image {
  display: block;
}

.row-image {
  display: none;
}

.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  padding: 10px 0;
}

.pagination-container label {
  font-family: Roboto;
  font-size: 14px;
  color: #0956cc;
}

.pagination-container select {
  margin-left: 5px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #0956cc;
  font-family: Roboto;
  font-size: 14px;
  color: #0956cc;
}

.page-btn {
  margin: 0 5px;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #0956cc;
  background-color: white;
  color: #0956cc;
  cursor: pointer;
}

.page-btn.active {
  background-color: #0956cc;
  color: white;
}

.page-btn:hover {
  background-color: #0956cc;
  color: white;
}

/* Media queries for mobile responsiveness */
@media (max-width: 768px) {
  .table-container {
    padding: 10px;
  }

  .data-table th,
  .data-table td {
    padding: 8px;
    font-size: 12px;
  }

  .download-btn {
    padding: 4px 8px;
    font-size: 12px;
  }

  .download-btn img {
    height: 12px;
  }

  .header-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .pagination-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .pagination-container select {
    margin-left: 0;
  }
}

@media (max-width: 480px) {
  .table-container {
    padding: 5px;
  }

  .data-table th,
  .data-table td {
    padding: 4px;
    font-size: 10px;
  }

  .header-container {
    flex-direction: row;
    align-items: flex-start;
  }

  .download-btn {
    margin-top: 15px;
    padding: 2px 4px;
    font-size: 10px;
  }

  .download-btn img {
    height: 10px;
  }

  .pagination-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .pagination-container select {
    margin-left: 0;
  }
}
