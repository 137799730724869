/* Basic styles to mimic MUI Box */
.custom-box {
    padding: 16px;
    background-color: #fcfcfc;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    flex: 1;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px; /* Adjust as needed */
  }
  .header {
    position: relative; /* Add this to establish a stacking context */
    z-index: 1; /* Ensure the header is above other content */
}
  
  .title {
    font-size: 24px; /* h3 equivalent */
    font-weight: 400; /* MUI Typography variant="h3" */
    margin: 0;
  }
  
  /* Mimic MUI FormControl + Select */
  .select-container {
    position: relative;
    z-index: 100;

  }
  
  .select-container select {
    padding: 8px 32px 8px 8px; /* Adjust padding and size as needed */
    min-width: 120px;
    cursor: pointer;
  }
  
  /* Custom List Styles */
  .custom-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 350px;
    overflow-y: auto;
    border: 1px solid #e0e0e0; /* Light grey border */
    border-radius: 10px;
    background-color: #ffffff;
  }
  
  .custom-list h4 {
    margin: 0;
    background-color: #e2eeff; /* Light blue, similar to MUI's ListSubheader */
    padding: 10px;
    border-top: 1px solid #e0e0e0; /* Separates sections */
    border-radius: 10px 10px 0 0; /* Rounded top corners */
  }
  
  .custom-list-item {
    padding: 10px;
    border-bottom: 1px solid #e0e0e0; /* Separates items */
  }
  
  /* Last item border removal */
  .custom-list-item:last-child {
    border-bottom: none;
  }

 
  .map-container {
    width: 100%; /* Full width of its parent container */
    height: 100%; /* Full height of its parent container */
    overflow: hidden; /* This prevents any scrolling within the map container */
    position: relative; /* Context for absolute positioning of child elements */
}

.map-container svg, .map-container canvas {
    width: 100%; /* Full width of its parent container */
    height: auto; /* Scale height to maintain aspect ratio */
    max-height: 100%; /* Ensure it doesn't exceed the parent's height */
    display: block; /* Remove extra space below inline elements */
    position: absolute; /* Absolute positioning relative to .map-container */
    top: 0; /* Align to the top of the container */
    left: 0; /* Align to the left of the container */
    overflow: hidden; /* Ensures that any overflow is hidden, should not be necessary with absolute positioning */
}
