.superadmin-tickets {
    background-color: #f2f7ff;
    overflow-y: auto;
    width: 100%;
    margin: 0;
    margin-top: 107px;
    height: 1111px;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .superadmin-ticket-header {
    font-family: Baumans;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
  }
  .raise-ticket{
    display: flex;
    align-items: center;
    background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
    box-shadow: 4px 4px 16px 0px #ffffff33 inset,
      -4px -4px 16px 0px #00000040 inset;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    font-family: Roboto;

  }
  @media (max-width: 480px) {
    .superadmin-tickets{
      margin-top: 180px;
    }
  }
  