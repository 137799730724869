.users-profile {
  background-color: #f2f7ff;
  overflow-y: auto;
  width: 100%;
  margin: 0;
  margin-top: 107px;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.user-image-logout-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.profile-image-wrapper {
  position: relative;
  width: 140px;
  height: 140px;
  overflow: visible;
}

.profile-image {
  border-radius: 50%;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-image img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.edit-image-button {
  position: absolute;
  bottom: 1px;
  right: 1px;
  border: none;
  box-shadow: 0px 4px 16px 0px rgba(9, 86, 204, 0.05);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  background: rgba(242, 247, 255, 1);
}

.edit-image-button img {
  display: block;
  width: 20px;
  height: auto;
}

.logout-button {
  color: white;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px 6px 12px;
  gap: 12px;
  border-radius: 24px;
  background: rgba(184, 12, 12, 1);
  box-shadow: 4px 4px 16px 0px rgba(255, 255, 255, 0.2) inset;
  box-shadow: -4px -4px 16px 0px rgba(0, 0, 0, 0.25) inset;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  letter-spacing: 0.02em;
  text-align: left;
  align-self: flex-start;
}

.user-form-data {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.userform-input-container,
.userform-textarea-container {
  position: relative;
  width: 90%;
  margin-bottom: 15px;
}

.form-label {
  align-self: flex-start;
  color: rgba(84, 85, 92, 1);
  font-family: Roboto;
  font-size: 18px;
  font-weight: 800;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-bottom: 5px;
}

.form-input,
.form-textarea {
  width: 100%;
  border: none;
  border-bottom: 0.5px solid #0956cc;
  background-origin: border-box;
  background-clip: content-box, border-box;
  padding: 10px 0;
  padding-right: 40px;
  font-size: 16px;
  color: #000;
  background-color: transparent;
  background-image: linear-gradient(109.89deg, #0956cc 1.29%, #052b66 99.41%);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% 1px, auto;
}

.form-input::placeholder,
.form-textarea::placeholder {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
  opacity: 0.5;
}

.form-input:focus,
.form-textarea:focus {
  outline: none;
  background-size: 100% 2px, auto;
}

.form-textarea {
  padding: 10px 0;
  resize: vertical;
}

.input-icon,
.textarea-icon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.user-tenant-container .user-tenant-header p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(84, 85, 92, 1);
}
.user-tenant-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-tenant-container {
  width: 70%;
}

.user-tenant-add {
  height: 18px;
  cursor: pointer;
}

.tenant-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px 30px;
}

.user-tenant-card {
  background: #ffffff;
  border-radius: 16px;
  padding: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 16px 0px rgba(9, 86, 204, 0.05);
}

.tenant-image {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-bottom: 2px;
}

.user-tenant-name {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  letter-spacing: 0.02em;
  text-align: center;
  color: rgba(84, 85, 92, 1);
}

.manage-button {
  color: white;
  cursor: help; /* Change cursor to help */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 9px 4px 9px;
  gap: 12px;
  border-radius: 24px;
  border: 1px solid rgba(9, 86, 204, 1);
  opacity: 0px;
  background-color: transparent;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  letter-spacing: 0.02em;
  color: rgba(9, 86, 204, 1);
}

.manage-button::after {
  content: "Coming Soon"; /* Text to display */
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 538949328;
  visibility: hidden; /* Initially hide the text */
  opacity: 0; /* Initially make it transparent */
  transition: opacity 0.3s ease; /* Add a smooth transition effect */
}

.manage-button:hover::after {
  visibility: visible; /* Show the text on hover */
  opacity: 1; /* Make it fully visible */
}

.user-card-container .user-card-header p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(84, 85, 92, 1);
}
.user-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-card-container {
  width: 70%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.user-card-add {
  height: 18px;
  cursor: pointer;
}

.user-card-box-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(42%, 1fr));
  justify-content: space-between;
  gap: 5% 14%;
}

.credit-card {
  display: flex;
  align-items: center;
  background: #ffffff;
}
.credit-card-logo {
  width: 60px;
  height: auto;
  margin-right: 14px;
}

.credit-card-details {
  flex-grow: 1;
}

.credit-card-number,
.credit-card-expiry {
  margin-bottom: 10px;
}
.credit-card-number {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-bottom: 5px;
}

.credit-card-expiry {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(84, 85, 92, 1);
}
.credit-card-container {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(9, 86, 204, 0.05);
  margin-bottom: 20px; /* Space between cards */
  padding: 20px 20px 10px 20px;
}

.credit-card {
  display: flex;
  align-items: center;
}

.card-actions {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 10px;
}

.primary-checkbox {
  position: relative;
  display: inline-block;
  margin-right: 10px;
}

.primary-checkbox input {
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.primary-checkbox .checkmark {
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1.5px solid #000;
  display: inline-block;
  cursor: pointer;
  border-radius: 2px;
}

.primary-checkbox input:checked ~ .checkmark {
  border-radius: 2px;
  background-color: rgba(9, 86, 204, 1);
  border-color: rgba(9, 86, 204, 1);
  position: relative;
}

.primary-checkbox input:checked ~ .checkmark::after {
  content: "";
  position: absolute;
  left: -0.5px;
  top: -0.5px;
  height: calc(100% - 1.1px);
  width: calc(100% - 1.1px);
  background-color: rgba(9, 86, 204, 1);
  border: 1px solid #fff;
  border-radius: 2px;
}
.delete-icon {
  width: 16px;
  height: 16px;
  margin-left: auto;
  cursor: pointer;
}

.user-address-container .user-address-header p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(84, 85, 92, 1);
}

.user-address-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  width: 100%;
}

.user-address-container {
  width: 70%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.user-address-add {
  height: 18px;
  cursor: pointer;
}
.address-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: flex-start;
}

.user-address-card {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 16px 0px #0956cc0d;
  padding: 25px;
  width: 210px;

  min-height: 270px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  max-height: auto;
}

.address-type {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(84, 85, 92, 1);
  margin-bottom: 12px;
}

.address-info {
  margin-bottom: 16px;
}

.address-name {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.address-text {
  margin-bottom: 7px;
}

.address-text,
.address-phone {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: black;
  color: #54555c;
}

.address-phone {
  margin-bottom: 6px;
}
.manage-address-button {
  cursor: pointer;
  position: relative;
  align-self: flex-start;
  margin-top: auto;
  padding: 4px 9px 4px 9px;
  gap: 12px;
  border-radius: 24px;
  border: 1px solid rgba(9, 86, 204, 1);
  background-color: transparent;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  letter-spacing: 0.02em;
  color: rgba(9, 86, 204, 1);
}

.user-star {
  height: 19px;
  margin-right: 5px;
}

.profile-submit-button {
  width: 80px;
  height: 34px;
  padding: 10px 10px;
  border-radius: 24px;
  background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
  box-shadow: 4px 4px 16px 0px #ffffff33 inset,
    -4px -4px 16px 0px #00000040 inset;
  border: 0px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-popup {
  width: 53%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  z-index: 999999999;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .users-profile {
    margin-top: 150px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .user-image-logout-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .user-tenant-container,
  .user-card-container,
  .user-address-container {
    width: 100%;
  }

  .tenant-cards {
    grid-template-columns: repeat(2, 1fr);
  }

  .user-card-box-container {
    grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  }

  .address-cards-container {
    justify-content: center;
  }

  .edit-popup {
    width: 90%;
    height: 90%;
    overflow-y: auto;
  }
}
