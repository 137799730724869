.product-journey-modal {
    display: block;
    position: fixed;
    z-index: 1000000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .product-journey-modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-height: 80%;
    overflow: auto;
    position: relative;
  }
  
  .product-journey-close {
    color: #aaa;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 28px;
    font-weight: bold;
  }
  
  .product-journey-close:hover,
  .product-journey-close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .journey-input {
    margin-bottom: 20px;
  }
  
  .journey-input label {
    display: block;
    margin-bottom: 5px;
  }
  
  .journey-input input,
  .journey-input textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
  }
  
  .journey-controls {
    display: flex;
    justify-content: flex-end;
  }
  
  .journey-add,
  .journey-remove {
    cursor: pointer;
    font-size: 24px;
    margin-left: 10px;
    user-select: none;
  }
  
  .journey-add:hover,
  .journey-remove:hover {
    color: #0956cc;
  }
  
  .journey-submit-button-container {
    display: flex;
    justify-content: flex-end;
  }
  
  .journey-bottom-right-button {
    padding: 10px 20px;
    background-color: #0956cc;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px; /* Add margin to avoid overlapping */
  }
  
  .journey-bottom-right-button:hover {
    background-color: #074a9b;
  }
  
  .batch-inputs {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  .batch-inputs input {
width: 20%;  }
  
.batch-number-read {
    background-color: #f0f0f0;
    cursor: not-allowed;
  }
  