.tag-page {
    /* background-color: #F2F7FF; */
    background-color: white;
    overflow-y: auto;
    width: 100%;
    margin: 0;
    margin-top: 107px;
    height: 1111px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 30px;
    
  }
  
  .tag-box-container {
    background-color: white;
    width: 105%;
    border-radius: 8px;
    margin-top: 20px;
    /* overflow: hidden; */
    padding: 21px;
  }
  
.tag-box-upper-content{
    display: flex;
    justify-content: space-between;
    
}


.tag-horizontal-line {
    width: 100%;
    height: 0.6px;
    background-color: rgb(219, 218, 218);
    margin: 17px auto; 
  }
  
  .tag-order-text{
display: flex;
justify-content: center;
font-family: Roboto;
  text-align: left;
  font-size: 29px;
  font-weight: 700;
  line-height: 16.41px;
  letter-spacing: 0.02em;
  color: black;
  margin-bottom: 30px;
  }

  .tag-cashier-customer{
    flex-direction: row;
    display: flex;
    justify-content: space-around;
  }

  .tag-custom-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
  }
  
  .tag-table-header {
    display: flex;
    justify-content: space-between;
    padding: 8px 8px 0 8px;
    font-weight: bold;
  }
  
 .tag-table-row {
  display: flex;
  justify-content: space-between;
  align-items: center; /* This will vertically center align items */
  padding: 8px;
}
.tag-table-row > div {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
  }
  
  .tag-table-row img {
    width: 70px; /* Adjust as needed */
  }
  
  .add-nfc-button {
    margin-top: 10px;
    background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
    box-shadow: 4px 4px 16px 0px #ffffff33 inset,
      -4px -4px 16px 0px #00000040 inset;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-family: Roboto;
  }
 
  .nfc-table-delete-btn {
    display: flex;
    justify-content: center; 
    align-items: center;
    
  }

  .delte-btn-table{
    background: linear-gradient(92.49deg, #f44352 0%, #fb0000 100%);
    box-shadow: 4px 4px 16px 0px #ffffff33 inset,
      -4px -4px 16px 0px #00000040 inset;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 25px;
    cursor: pointer;
    font-size: 14px;
    font-family: Roboto;
  }
.nfc-img {
    align-self: center; /* This will center align the image within its flex item */
  }
  
  .tag-table-header > div {
    flex: 1;
    text-align: center; /* This centers the text in the header */
  }

  .nfc-first-column {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    flex-direction: row; /* Lay out children in a row */
  }
  
  .table-tag-horizontal-line {
    width: 100%;
    height: 0.6px;
    background-color: rgb(133, 133, 133);
    margin: auto; 
    margin-top: 5px;
  }
  .nfc-first-column select {
    height: 40px; /* Increasing the height */
    border-radius: 6px; /* Adding border-radius */
    border: 1px solid rgb(189, 188, 188);
    padding: 5px;
  }

  .tag-table-row input {
    height: 40px; /* Increasing the height */
    border-radius: 2px; /* Adding border-radius */
    border: 1px solid rgb(189, 188, 188);
    padding: 5px;
    width: 60%;
  }
  
  .order-placed-checkout {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 30px;
  }
  
  .checkout-item {
    display: flex;
    justify-content: space-between;
    width: 100%; /* Adjust width as needed */
    margin-bottom: 5px; /* Spacing between items */
  }
  
  .checkout-line {
    width: 100%; /* Full width line */
    border-bottom: 0.7px solid rgb(144, 141, 141); /* Line style */
    margin: 10px 0; /* Spacing above and below the line */
  }
  
  .checkout-total {
    display: flex;
    justify-content: space-between;
    width: 100%; /* Adjust width as needed */
    font-weight: bold; /* Make total bold */
  }
  
  .tag-payment-btn{
    background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-family: Roboto;
    margin-top: 20px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

  }
