.custom-date-picker-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999; /* Ensure the overlay is above other content */
}

.custom-date-picker {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 23%;
}

.custom-date-picker .button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.custom-date-picker .apply-button,
.custom-date-picker .cancel-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.custom-date-picker .date-picker-apply-button {
  width: 80px;
  height: 34px;
  padding: 10px 10px;
  border-radius: 24px;
  background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
  box-shadow: 4px 4px 16px 0px #ffffff33 inset,
    -4px -4px 16px 0px #00000040 inset;
  border: 0px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.custom-date-picker .date-picker-cancel-button {
  width: 80px;
  height: 34px;
  padding: 10px 10px;
  border-radius: 24px;
  background: linear-gradient(92.49deg, #cc0909 0%, #a11a1a 100%);
  box-shadow: 4px 4px 16px 0px #ffffff33 inset,
    -4px -4px 16px 0px #00000040 inset;
  border: 0px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.date-picker-select-date {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(84, 85, 92, 1);
}

.date-picker-mini-header {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(84, 85, 92, 1);
  margin-bottom: 4px;
}

.date-picker-box {
  width: 120%;
  height: 26px;
  border-radius: 6px;
  border: 1px solid black;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .custom-date-picker {
    width: 90%;
    padding: 15px;
  }

  .date-picker-box {
    width: 100%;
  }

  .button-group {
    flex-direction: row;
    justify-content: center;
    gap: 10px;
  }

  .date-picker-apply-button,
  .date-picker-cancel-button {
    width: 80px;
    height: 40px;
    text-align: center;
  }

  .date-picker-select-date,
  .date-picker-mini-header {
    text-align: center;
    font-size: 16px;
  }

  .custom-date-picker > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
