
  .card {
    background-color: #fff;
    border-radius: 10px;
    padding: 1em;
    position: relative;
  }
  
  .card::before {
    content: "";
    display: block;
    width: 100%;
    padding-top: calc(200 / 281 * 100%); /* Aspect ratio padding */
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .card-two {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  
  .top-container {
    display: flex;
    align-items: center;
    position: relative;
    gap: 4px;
    margin-bottom: 1.6rem;
  }
  
  
  
  @media (max-width: 768px) {
    .cards-container {
      grid-template-columns: 1fr;
    }
  }
  
  

    .scanner-btn-align{
        display: flex;
        justify-content: space-between;
        
    }

    .dropdown {
        position: relative;
        display: inline-block;
        /* bottom: 1px; */
      }
      
      .dropdown-button {
        background: linear-gradient(92.49deg, #0956CC 0%, #1A4E9F 100%);
        box-shadow: 4px 4px 16px 0px #FFFFFF33 inset, -4px -4px 16px 0px #00000040 inset;
        border: none;
        color: white;
        padding: 6px 12px 6px 12px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 23px;
        gap: 12px;
        font-size: 14px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0.02em;
      }
      
      .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f1f1f1;
        min-width: 60px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        flex-direction: column;
        font-family: Roboto;
      }
      
      .dropdown-content button {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        background: transparent;
        border: none;
        width: 100%;
        text-align: left;
        border-bottom: 1px solid #ddd;
      }
      
      .dropdown-content button:hover {
        background-color: #E2EEFF;;
      }
      
      .dropdown-content button:last-child {
        border-bottom: none;
      }
      
      .dropdown button:hover, .dropdown button:focus {
        background-color: #f0f5fc;
      }
      
      .dropdown:hover .dropdown-content {
        display: flex;
      }
            

      
.scanner-bottom-container {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }
  
  .scan-category {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }
  
  .scan-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Roboto;
font-size: 14px;
font-weight: 300;
line-height: 16px;
letter-spacing: 0.02em;
text-align: left;

  }
  
  .scan-bar-container {
    width: 100%;
  }
  
  .scan-bar {
    height: 10px;
    border-radius: 5px;
    background-color: #ddd;
  }
  
  .scan-number {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.02em;
  }
      

  .card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9); /* Semi-transparent white */
    backdrop-filter: blur(10px); /* Apply blur effect */
    z-index: 99; /* Ensure it appears above the card */
    cursor: pointer;
  }
  