/* Tooltip.css */
.nav-tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .nav-tooltip .nav-tooltiptext {
    visibility: hidden;
    width: 60px;
    background-color: rgb(178, 202, 244);
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 2px;
    position: absolute;
    z-index: 1;
    bottom: -70%;
    left: 234%;
    margin-left: -100px;
    opacity: 0;
    transition: opacity 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .nav-tooltip:hover .nav-tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  