.status-container {
  display: flex;
  margin-top: 13px;
}

.status-left-section {
  width: 30%;
  display: flex;
  position: relative;
}

.status-product-image {
  width: 100px;
  border-radius: 6px;
  height: auto;
  margin-right: 10px;
}

.status-left-section::after {
  content: "";
  display: block;
  width: 0.7px;
  height: 100px;
  background-color: grey;
  position: absolute;
  right: 0;
  top: 55%;
  transform: translateY(-50%);
}

.status-product-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.status-product-name {
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
}

.status-item-name {
  margin: 0;
  padding: 0;
}

.status-right-section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10px;
}

.tracking-id {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
}

.timeline-status-container {
  height: 80px;
}

.tracking-id-number {
  color: #0956cc;
  text-decoration: underline;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  cursor: pointer;
}

.actions-container {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  margin-top: 13px;
}

.cancel-order-button {
  width: 108px;
  height: 28px;
  padding: 6px 12px;
  gap: 12px;
  border-radius: 24px;
  border: 1px solid #b80c0c;
  color: #b80c0c;
  background: transparent;
  cursor: pointer;
}

.report-problem-container {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.report-icon {
  margin-right: 4px;
}

.report-problem-text {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #0956cc;
  cursor: pointer;
  text-decoration: none;
}

.status-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.redownload-qr {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
  box-shadow: 4px 4px 16px 0px #ffffff33 inset, -4px -4px 16px 0px #00000040 inset;
  border: none;
  color: white;
  padding: 10px 13px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  border-radius: 24px;
  font-family: Roboto;
  margin-right: 10px;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .status-container {
    flex-direction: column;
  }

  .status-left-section {
    width: 100%;
  }

  .status-right-section {
    width: 100%;
    margin-top: 20px;
  }

  .timeline-status-container {
    width: 100%;
    overflow-x: auto;
  }
}
