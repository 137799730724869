.custom-buttons-container {
  display: flex;
  align-items: center;
  margin-top: 40px;
  cursor: pointer;
}

.buttons-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 194px;
  margin-left: 16px;
}

.button-text-item {
color: white;  }


.button-text {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.02em;
}

.button-text.active {
  color: #7fa3da;
}

