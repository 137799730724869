.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
  }
  
  .modal-content {
    background-color: #fff;
    border-radius: 20px;
    width: 360px;
    max-width: 100%;
    padding: 20px;
    position: relative;
    overflow: hidden;
  }
  
  .mobile-screen-container {
    width: 100%;
    height: 580px;
    border-radius: 30px;
    border: 16px solid #ccc;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
  }
  
  .mobile-screen-header {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #333;
    color: white;
    font-size: 18px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }
  
  .mobile-screen-body {
    width: 100%;
    height: calc(100% - 50px); /* Adjust height based on header height */
    overflow: hidden;
    position: relative;
  }
  
  .mobile-screen-iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 10px;
    overflow: auto;
  }
  