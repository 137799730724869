.login-tenant-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@media (min-width: 768px) {
  .login-tenant-container {
    flex-direction: row;
  }
}

.left-side {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-basis: 55%;
  height: 100vh;
  background-color: #e2eeff;
  clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);
  padding: 20px;
}

@media (max-width: 767px) {
  .left-side {
    display: none;
  }
}

.text-top {
  text-align: left;
  padding: 20px;
  font-family: Roboto;
  padding-right: 30px;
}

.text-top p {
  font-size: 26px;
}

@media (max-width: 767px) {
  .text-top p {
    font-size: 18px;
  }
}

.login-image-center {
  position: relative;
  width: fit-content;
  margin: auto; 
}

.login-background-image {
  position: absolute;
  top: -70px;
  right: 140px;
  z-index: 1;
  width: 100%; 
  height: auto; 
}
.login-background-image-new {
  position: absolute;
  top: -20px;
  right: 350px;
  z-index: 1;
 width: 240px;
 height: 220px;
}

.login-base-image {
  display: block;
  position: relative;
  z-index: 2;
}

.login-overlay-image {
  position: absolute;
  top: 70px; 
  left: 267px;
  width: 90px; 
  z-index: 3;
  height: 197px;
  border-radius: 10px;
}



.login-image-center {
  height: 27em;
  position: relative;
  left: -50px;
}

@media (max-width: 767px) {
  .image-center {
    display: none;
  }
}

.logo-bottom-left {
  align-self: flex-start;
}

.logo-bottom-left img {
  width: 100px; /* Adjust the size as needed */
}

@media (max-width: 767px) {
  .logo-bottom-left {
    align-self: center;
    margin-bottom: 10px;
  }
}

.right-side {
  flex-basis: 40%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .right-side {
    flex-basis: 100%;
    height: auto;
    padding: 20px;
  }
}

.logo {
  width: 29rem;
  height: auto;
}

.login-right-side {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-welcome {
  font-family: Roboto;
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
}

@media (max-width: 767px) {
  .login-welcome {
    font-size: 24px;
    text-align: center;
  }
}

.login-top-texts {
  display: flex;
  flex-direction: column;
}

.login-arvocloud {
  font-family: Baumans;
  font-size: 48px;
  font-weight: 400;
  line-height: 57px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #0956cc;
}

@media (max-width: 767px) {
  .login-arvocloud {
    font-size: 32px;
    text-align: center;
  }
}

.login-input-box-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
}

.box-login-input {
  height: 56px;
  width: 500px;
  border: 2px solid #9da1b8;
  border-radius: 12px;
  padding-left: 13px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #54555c;
}

@media (max-width: 767px) {
  .box-login-input {
    width: 100%;
    font-size: 16px;
    height: 48px;
  }
}

.password-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.icon-eye {
  position: absolute;
  right: 20px;
  cursor: pointer;
  width: 24px;
}

.forgot-password-text {
  width: 100%;
  margin-top: -3px;
  color: #54555c;
  cursor: pointer;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.02em;
  text-align: right;
  opacity: 0.7;
}

.login-button {
  background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
  box-shadow: 4px 4px 16px 0px #ffffff33 inset, -4px -4px 16px 0px #00000040 inset;
  width: 501px;
  height: 57px;
  padding: 16px;
  border-radius: 12px;
  opacity: 1;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-family: Roboto;
  margin-top: 18px;
}

.login-button.inactive {
  background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
  box-shadow: 4px 4px 16px 0px #ffffff33 inset, -4px -4px 16px 0px #00000040 inset;
  width: 501px;
  height: 57px;
  padding: 16px;
  border-radius: 12px;
  opacity: 0.5;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-family: Roboto;
  margin-top: 18px;
}

@media (max-width: 767px) {
  .login-button,
  .login-button.inactive {
    width: 100%;
    font-size: 16px;
    height: 48px;
  }
}

.text-btn-below {
  margin-top: 12px;
}

.having-trb {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.02em;
  margin-right: 5px;
  opacity: 0.57;
}

.contact-us {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #0956cc;
}

.tenant-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@media (min-width: 768px) {
  .tenant-container {
    flex-direction: row;
  }
}

.tenants-right-side {
  flex-basis: 40%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .tenants-right-side {
    flex-basis: 100%;
    height: auto;
    padding: 20px;
  }
}

.tenant-right-container {
  display: flex;
  flex-direction: column;
}

.tenant-hello {
  font-family: Roboto;
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0.02em;
  color: #54555c;
}

@media (max-width: 767px) {
  .tenant-hello {
    font-size: 24px;
    text-align: center;
  }
}

.tenant-brand {
  font-family: Baumans;
  font-size: 48px;
  font-weight: 400;
  line-height: 57px;
  letter-spacing: 0.02em;
  color: #0956cc;
}

@media (max-width: 767px) {
  .tenant-brand {
    font-size: 32px;
    text-align: center;
  }
}

.tenant-top-texts {
  display: flex;
  flex-direction: column;
}

.tenant-custom-dropdown {
  position: relative;
  display: inline-block;
  margin-top: 90px;
}

.tenant-dropdown-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border: 2px solid #9da1b8;
  cursor: pointer;
  border-radius: 9px;
  width: 470px;
  height: 50px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.02em;
  color: #54555c;
  padding-left: 10px;
}

@media (max-width: 767px) {
  .tenant-dropdown-button {
    width: 100%;
    font-size: 18px;
    height: 48px;
  }
}

.tenant-dropdown-arrow {
  width: 18px;
  height: auto;
  margin-right: 8px;
}

.tenant-dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 470px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 9px;
}

@media (max-width: 767px) {
  .tenant-dropdown-content {
    min-width: 100%;
  }
}

.tenant-dropdown-item {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.tenant-dropdown-item:hover {
  background-color: #e9f1fc;
  border-radius: 9px;
}

.tenant-proceed-button {
  background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
  box-shadow: 4px 4px 16px 0px #ffffff33 inset, -4px -4px 16px 0px #00000040 inset;
  width: 470px;
  height: 50px;
  padding: 11px;
  border-radius: 12px;
  opacity: 1;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-family: Roboto;
  margin-top: 50px;
}

@media (max-width: 767px) {
  .tenant-proceed-button {
    width: 100%;
    font-size: 16px;
    height: 48px;
  }
}

.tenant-text-btn-below {
  margin-top: 12px;
  display: flex;
  justify-content: center;
}

.having-trb {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.02em;
  margin-right: 5px;
  opacity: 0.57;
}

.contact-us {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #0956cc;
}

.contact-us {
  cursor: pointer;
}
