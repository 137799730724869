.dropdown {
  position: relative;
  display: inline-block;
}

.navbar-dropdown-button {
  background: linear-gradient(92.49deg, #0956CC 0%, #1A4E9F 100%);
  box-shadow: 4px 4px 16px 0px #FFFFFF33 inset, -4px -4px 16px 0px #00000040 inset, 0px 4px 4px 0px #00000040;
  color: white;
  padding: 10px 20px; /* Increased padding for better touch targets */
  font-size: 16px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 40px;
  border-radius: 24px;
  font-family: 'Roboto', sans-serif;
  transition: background-color 0.3s ease; /* Smooth background color transition */
}

.navbar-dropdown-button:hover {
  background: linear-gradient(92.49deg, #1A4E9F 0%, #0956CC 100%); /* Change gradient on hover */
}

.dropdown-content {
  position: absolute;
  background-color: #ffffff; /* White background for contrast */
  min-width: 220px; /* Slightly increased width */
  max-height: 300px; /* Added max height for overflow handling */
  overflow-y: auto; /* Enable scrolling */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5; /* Improved line height for readability */
  text-align: left;
}

.dropdown-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: background-color 0.2s ease; /* Smooth background color transition */
}

.dropdown-item:hover {
  background-color: rgba(218, 230, 247, 0.5); /* Slightly transparent background on hover */
  border-radius: 4px; /* Rounded corners on hover */
}

.search-input {
  width: calc(100% - 24px); /* Full width minus padding */
  padding: 10px; /* Increased padding */
  border: 1px solid #ccc; /* Border for visibility */
  border-radius: 4px; /* Rounded corners */
  margin: 10px 12px; /* Margin for spacing */
  font-family: 'Roboto', sans-serif; /* Consistent font */
  font-size: 16px; /* Consistent font size */
}

.search-input:focus {
  outline: none; /* Remove outline on focus */
  border-color: #0956CC; /* Change border color on focus */
}

.nav-btn-img-two {
  width: 12px;
  height: 9px;
  transition: transform 0.3s ease; /* Smooth transition for rotation */
}

.navbar-dropdown-button:hover .nav-btn-img-two {
  transform: rotate(180deg); /* Rotate icon on hover */
}
