.forgot-password-container {
    display: flex;
    min-height: 100vh;
  }
  
  .left-side {
    flex-basis: 55%;
    height: 100vh;
    background-color: #e2eeff;
    clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);
    display: flex;
    justify-content: center;
    position: relative;
  }
  
  .logo-container {
    position: absolute;
    left: 40%;
    top: 47%;
    transform: translate(-50%, -50%);
  }
  
  .right-side {
    flex-basis: 40%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .logo {
    width: 46rem;
    height: auto;
  }
  
  .forgot-password-right-side {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .forgot-password-welcome {
    font-family: Roboto;
    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #54555c;
  }
  
  .forgot-password-top-texts {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .forgot-password-arvocloud {
    font-family: Baumans;
    font-size: 48px;
    font-weight: 400;
    line-height: 57px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #0956cc;
  }
  
  .forgot-password-input-box-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
    width: 100%;
  }
  
  .box-forgot-password-input {
    height: 56px;
    width: 100%;
    border: 2px solid #9da1b8;
    border-radius: 12px;
    padding-left: 13px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #54555c;
  }
  .box-forgot-password-input::placeholder {
    color: #54555c;
    opacity: 0.58;
  }
  
  .box-forgot-password-input:-ms-input-placeholder {
    color: #54555c;
    opacity: 0.58;
  }
  
  .box-forgot-password-input::-ms-input-placeholder {
    color: #54555c;
    opacity: 0.58;
  }
  
  .forgot-password-button {
    background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
    box-shadow: 4px 4px 16px 0px #ffffff33 inset,
      -4px -4px 16px 0px #00000040 inset;
    width: 100%;
    height: 57px;
    padding: 16px;
    border-radius: 12px;
    opacity: 1;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 18px;
    font-family: Roboto;
    margin-top: 18px;
  }
  
  .text-btn-below {
    margin-top: 12px;
  }
  
  .having-trb {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.02em;
    margin-right: 5px;
    opacity: 0.57;
  }
  .contact-us {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #0956cc;
    cursor: pointer;
  }
  
  .forgot-inputStyle {
    width: 40% !important;
    height: 66px;
    border-radius: 7px;
    border: 0px;
  margin-right: 8px;
    background: #e2eeff;
    font-size: 20px;
  }