.status-container {
  display: flex;
  margin-top: 13px;
}

.status-left-section {
  width: 30%;
  display: flex;
  position: relative;
}

.status-brand-image {
  width: 100px;
  border-radius: 50%;
  height: auto;
  margin-right: 10px;
}

.status-left-section::after {
  content: "";
  display: block;
  width: 0.7px;
  height: 100px;
  background-color: grey;
  position: absolute;
  right: 0;
  top: 55%;
  transform: translateY(-50%);
}
.status-product-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.status-product-name {
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
}

.status-address {
  margin: 0;
  padding: 0;
  font-size: 11px;
  font-family: Roboto;
}

.status-right-section {
  flex-grow: 1; /* This will make the right section fill the height */
  display: flex;
  flex-direction: column; /* Stack timeline and tracking ID */
  justify-content: space-between;
  margin-left: 10px;
}

.tracking-id {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
}

.timeline-status-container {
  height: 80px;
}
.tracking-id-number {
  color: #0956cc;
  text-decoration: underline;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  cursor: pointer;
}

.actions-container {
  display: flex;
  justify-content: flex-end; /* Aligns actions to the right */
  padding: 10px;
  margin-top: 13px;
}

.cancel-order-button {
  width: 108px;
  height: 28px;
  padding: 6px 12px;
  gap: 12px;
  border-radius: 24px;
  border: 1px solid #B80C0C;
  color: #B80C0C;
  background: transparent; /* Adjust as needed */
  cursor: pointer;
}

.report-problem-container {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.report-icon {
  /* Style your icon here */
  margin-right: 4px;
}

.report-problem-text {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #0956CC;
  cursor: pointer;
}
.status-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}