.graph-container {
    width: 50%;
    background-color: white;
    margin-top: 20px;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
  }
  
  .graph-content {
    width: 100%; 
    height: auto;
    overflow: auto;
    border-radius: 20px;
    
  }
  