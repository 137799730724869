.orders {
  background-color: #f2f7ff;
  overflow-y: auto;
  width: 100%;
  margin: 0;
  margin-top: 107px;
  padding-left: 20px;
  padding-right: 20px;
}

.orders-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orders-text {
  font-family: Baumans;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
}

.order-filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: 85px;
  height: 28px;
  padding: 6px 10px;
  border-radius: 24px;
  border: 1px solid #0956cc;
  opacity: 1;
  background-color: transparent;
  color: #0956cc;
  font-size: 14px;
  cursor: pointer;
}

.order-filter-button img {
  width: 13px;
  height: 13px;
}

.orders-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
}

.orders-table td {
  border-bottom: 0.5px solid #0956cc;
  padding: 8px;
  text-align: left;
}

.orders-table td {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
  padding: 10px 0 10px 0;
  padding-top: 11.5px;
  padding-bottom: 11.5px;
}

.orders-table th {
  border-top: 1px solid #0956cc;
  border-bottom: 1px solid #0956cc;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #0956cc;
  padding-top: 4px;
  padding-bottom: 4px;
}

.orders-table tr {
  width: 100%;
}

.order-table-timestamp {
  color: #54555c;
  opacity: 0.6;
  margin-top: 3.5px;
}

.order-table-items {
  padding: 3px;
}

.order-expand-icon {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.order-expand-icon.rotated {
  transform: rotate(180deg);
}

.status-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spacer td {
  transition: height 0.3s ease;
  height: 0;
  background-color: transparent;
  overflow: hidden;
}

.expanded td {
  height: auto;
  padding: 0;
  background-color: transparent;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(720deg);
  }
}

.reload-icon {
  cursor: pointer;
}

.rotate {
  animation: rotate 2s linear infinite;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .orders {
    padding-left: 10px;
    padding-right: 10px;
  }

  .orders-header {
    margin-top: 70px;
  }

  .orders-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .orders-table th,
  .orders-table td {
    padding: 8px;
  }

  .orders-table th {
    font-size: 14px;
  }

  .orders-table td {
    font-size: 13px;
  }

  .status-container {
    flex-direction: column;
  }

  .status-left-section {
    width: 100%;
  }

  .status-right-section {
    width: 100%;
    margin-top: 20px;
  }

  .timeline-status-container {
    width: 100%;
    overflow-x: auto;
  }
}
.popup-content {
  height: 74%;
  width: 60%;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #f00;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.popup-flex {
  display: flex;
  justify-content: space-around;
  flex-grow: 1;
  margin-top: 40px;
}

.customize-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.customize-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.customize-item label {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

.customize-item input,
.customize-item select {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.customize-item button {
  align-self: center;
  padding: 10px 20px;
  background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
  box-shadow: 4px 4px 16px 0px #ffffff33 inset, -4px -4px 16px 0px #00000040 inset;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  border-radius: 24px;
  font-family: "Roboto", sans-serif;
}

.qr-code-section {
  display: flex;
  justify-content: center;
  align-items: center;
}


@media (max-width: 768px) {
  .popup-content {
    width: 90%;
    height: auto;
    padding: 10px;
  }

  .popup-flex {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .customize-section,
  .qr-code-section {
    width: 100%;
  }
}
