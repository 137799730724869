/* CustomTable.css */
.customTableContainer {
 
  margin: 10px 0;
  background-color: white;
  border-radius: 20px;
  padding: 10px 20px 20px 20px;
}

.customTable {
  overflow: auto;
  width: 100%;
  border-collapse: collapse;
}

.customTable th,
.customTable td {
  text-align: center;
  padding: 16px;
  width: 20%;
  border: none;
}

.customTable th {
  background-color: transparent;
  border-top: 1px solid #0956cc;
  border-bottom: 1px solid #0956cc;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.41px;
  letter-spacing: 0.02em;
  color: #0956cc;
  padding-top: 10px;
  padding-bottom: 10px;
}

.customTable tbody tr:hover {
  background-color: rgb(243, 249, 255);
}
.table-content {
  background-color: transparent;
}

.table-heading {
  color: #0956cc;
}

.table-usage-print {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.usage-metrics-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-left: 10px; */
}

.print-btn {
  padding: 6px 12px 6px 12px;
  border-radius: 24px;
  background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
  box-shadow: 4px 4px 16px 0px #ffffff33 inset;
  box-shadow: -4px -4px 16px 0px #00000040 inset;
  border: none;
  color: white;
  cursor: pointer;
}

.print-btn img{
margin-left: 5px;
position: relative;
top: 1px;
}
.customTable td{
  font-family: Roboto;
font-size: 14px;
font-weight: 400;
line-height: 16.41px;
letter-spacing: 0.02em;
text-align: left;
color: #54555C;
text-align: center;
}



.table-row:hover .row-image {
  display: block;
}

.row-image {
  display: none;
}