.info-container {
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;
  margin-right: 40px;
}

.icon-circle, .image-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.2px solid #0956CC;
  /* flex-shrink: 1 */
}

.img-info{
  height: 24px;
  width: 24px;
}
.profile-img-info{
  height: 100%;
  width: 100%;
}
