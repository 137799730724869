.graph-containersing {
  width: 55%;
  background-color: white;
  margin-top: 20px;
  border-radius: 20px;
  height:'500px';
  overflow: hidden;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.graph-contentsing {
  width: 100%; 
  height: auto;
  overflow: auto;
  border-radius: 20px;
  
}
