.search-popup-modal {
     position: fixed;
    top: 60%;
    left: 50%; 
     transform: translate(-50%, -50%); 
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 9999;
    max-width: 80%;
    width: 600px; /* Adjust width as needed */
  }
  
  
  .search-popup-header {
    display: flex;
    justify-content: flex-end;
  }
  
  .close-btn {
    background: none;
    border: none;
    margin:'5px';
    cursor: pointer;
  }
  