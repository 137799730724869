.technical-document {
    background-color: #F2F7FF;
    overflow-y: auto;
    width: 100%;
    margin: 0;
    margin-top: 107px;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .technical-content{
    width: 80%;
  }
  .technical-header span {
    font-family: Baumans;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  
  .technical-content h2 {
    font-size: 20px;
    margin-top: 20px;
    font-family: Roboto;
  }
  
  .technical-content h3 {
    font-size: 18px;
    margin-top: 20px;
    font-family: Roboto;
  }
  
  .technical-content hr {
    border: none;
    height: 1px;
    background-color: #ccc;
    margin-bottom: 20px;
  }
  
  .technical-content p,
  .technical-content ul {
    margin-bottom: 20px;
    font-size: 16px;
    font-family: Roboto;
  }
  
  .technical-content ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .technical-content a {
    color: #007bff;
    text-decoration: none;
  }
  
  .technical-content a:hover {
    text-decoration: underline;
  }
  