.superadmin-usermanager {
    background-color: #f2f7ff;
    overflow-y: auto;
    width: 100%;
    margin: 0;
    margin-top: 107px;
    height: 100vh;
    padding-left: 20px;
    padding-right: 20px;
  }
  .super-admin-last-updated {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.02em;
  }
  
  .superadmin-usermanager-header {
    font-family: Baumans;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
  }
.blur-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.blur-content {
  filter: blur(10px);
  position: relative;
  z-index: 1;
}

.center-image {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}