.sidebar {
  width: 20%;
  background-color: #031d45;
  height: 100vh;
  overflow-y: hidden;
  /* overflow-x: hidden; */
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 0; 
  top: 0;
}

.admin-sidebar-logo {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.admin-sidebar-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  margin: 10px;  
  width: 150px;
}

.admin-sidebar-logo-container img,
.user-container img {
  max-width: 100%;
  display: block;
}

.user-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 12px;
}

.user-image {
  /* border-radius: 50%; */
  height: 68px;
  width: 68px;
}

.user-dropdown {
  width: 100%;
  padding: 8px 0px 8px 12px;
  color: white;
  text-align: left;
  font-family: "Roboto", sans-serif;
  border: none;
  border-radius: 24px;
  background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
  box-shadow: 4px 4px 16px 0px #ffffff33 inset,
    -4px -4px 16px 0px #00000040 inset, 0px 4px 4px 0px #00000040;
font-size: 16px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0.02em;
}

.product-dropdown {
  width: 68%;
  position: relative;
}
.admin-sidebar-line {
  height: 2px;
  width: 100%;
  background-color: #1d365b;
}


.admin-sidebar-product-dropdown {
  position: relative;
  display: inline-block;
  width: 68%;
}

.admin-sidebar-update-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(92.49deg, #0956CC 0%, #1A4E9F 100%);
  box-shadow: 4px 4px 16px 0px #FFFFFF33 inset, -4px -4px 16px 0px #00000040 inset;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 24px;
  font-family: "Roboto", sans-serif;
  width: 100%;
  gap: 12px;
}

.admin-sidebar-dropdown-icon {
  width: 12px;
}

.admin-sidebar-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f0f5fc;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  right: 0;
  border-radius: 7px;
  text-align: center;
}

.admin-sidebar-product-dropdown .admin-sidebar-update-button:focus + .admin-sidebar-dropdown-content,
.admin-sidebar-product-dropdown .admin-sidebar-update-button:active + .admin-sidebar-dropdown-content,
.admin-sidebar-product-dropdown:hover .admin-sidebar-dropdown-content {
  display: block;
}

.admin-sidebar-dropdown-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.admin-sidebar-dropdown-item:hover {
  background-color: #E2EEFF;
  border-radius: 7px;
}
.admin-sidebar-bottom-arvo-logo{
  position: absolute;
  bottom: 0; /* Align to the bottom */
  left: 0; /* Align to the left */
  width: 100%; /* Span the full width */
  text-align: center; /* Center the content */
  padding: 10px 0 0 0; /* Add some padding at the bottom */
}

.arvo-text{
  height: 14px;
  margin-right: 4px;
}
.arvo-logo-brand{
  height: 13px;
}
.admin-sidebar-bottom-arvo-logo span{
  font-family: Roboto;
font-size: 13px;
font-weight: 400;
line-height: 14.06px;
letter-spacing: 0.02em;
text-align: left;
color: white;
margin-right: 4px;
}