  .subscription-containersb {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
    }
    
    .modaled {
      display: none;
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      background-color: rgba(0,0,0,0.4);
    }
    
    .modaled-content {
      background-color: #fefefe;
      margin: 15% auto;
      padding: 20px;
      border: 1px solid #888;
      width: 80%;
    }
    
    .close {
      color: #aaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
    }
    
    .close:hover,
    .close:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
    
    .show {
      display: block;
    }
    
    .subscription-buttons {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    
    /* button {
      margin: 0 10px;
      padding: 10px 20px;
      font-size: 16px;
      cursor: pointer;
    }
    */

    .cardings{
      position: relative;
      max-width: 300px;
      height: auto;
      background: linear-gradient(-45deg,#fe0847,#feae3f);
      border-radius: 15px;
      margin: 0 auto;
      padding: 40px 20px;
      -webkit-box-shadow: 0 10px 15px rgba(0,0,0,.1) ;
              box-shadow: 0 10px 15px rgba(0,0,0,.1) ;
    -webkit-transition: .5s;
    transition: .5s;
    }
    .cardings:hover{
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    .col-sm-4:nth-child(1) .cardings ,
    .col-sm-4:nth-child(1) .cardings .title .fa{
      background: linear-gradient(-45deg,#f403d1,#64b5f6);
    
    }
    .col-sm-4:nth-child(2) .cardings,
    .col-sm-4:nth-child(2) .cardings .title .fa{
      background: linear-gradient(-45deg,#ffec61,#f321d7);
    
    }
    .col-sm-4:nth-child(3) .cardings,
    .col-sm-4:nth-child(3) .cardings .title .fa{
      background: linear-gradient(-45deg,#24ff72,#9a4eff);
    
    }
    .cardings::before{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40%;
      background: rgba(255, 255, 255, .1);
    -webkit-transform: skewY(-5deg) scale(1.5);
            transform: skewY(-5deg) scale(1.5);
    }
    .title .fa{
      color:#fff;
      font-size: 60px;
      width: 100px;
      height: 100px;
      border-radius:  50%;
      text-align: center;
      line-height: 100px;
      -webkit-box-shadow: 0 10px 10px rgba(0,0,0,.1) ;
              box-shadow: 0 10px 10px rgba(0,0,0,.1) ;
    
    }
    .title h2 {
      position: relative;
      margin: 20px  0 0;
      padding: 0;
      color: #fff;
      font-size: 28px;

    }
    .price,.option{
      position: relative;

    }
    .price h4 {
    margin: 0;
    padding: 20px 0 ;
    color: #fff;
    font-size: 60px;
    }
    .option ul {
      margin: 0;
      padding: 0;
    
    }
    .option ul li {
    margin: 0 0 10px;
    padding: 0;
    list-style: none;
    color: #fff;
    font-size: 16px;
    }
    .cardingsbtn {
      position: relative;
      background: #fff;
      color : black;
      width: 150px;
      height: 40px;
      line-height: 40px;
      border-radius: 40px;
      display: block;
      text-align: center;
      margin: 20px auto 0 ;
      font-size: 16px;
      cursor: pointer;
      -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
              box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    
    }
    .cardings a:hover{
        text-decoration: none;
    }
    




    


  .cardsssss {
      z-index: 99999999999;
    width: 60rem;
      margin: auto;
      background: white;
      position:center;
      align-self: center;
      top: 50rem;
      border-radius: 1.5rem;
      box-shadow: 4px 3px 20px #3535358c;
      display:flex;
      flex-direction: row;
      
  }

  .leftside {
    background: #030303;
    width: 25rem;
    display: inline-flex;
      align-items: center;
      justify-content: center;
    border-top-left-radius: 1.5rem;
      border-bottom-left-radius: 1.5rem;
  }

  .product {
      object-fit: cover;
    width: 20em;
      height: 20em;
      border-radius: 100%;
  }

  .rightside {
      background-color: #ffffff;
    width: 35rem;
    border-bottom-right-radius: 1.5rem;
      border-top-right-radius: 1.5rem;
      padding: 1rem 2rem 3rem 3rem;
  }

.card-holder-card{
      display:block;
      font-size: 1.1rem;
      font-weight: 400;
      margin: .8rem 0;
  }

  .inputbox
  {
      color:#030303;
    width: 100%;
      padding: 0.5rem;
      border: none;
      border-bottom: 1.5px solid #ccc;
      margin-bottom: 1rem;
      border-radius: 0.3rem;
      font-family: 'Roboto', sans-serif;
      color: #615a5a;
      font-size: 1.1rem;
      font-weight: 500;
    outline:none;
  }

  .expcvv {
      display:flex;
      justify-content: space-between;
      padding-top: 0.6rem;
  }

  .expcvv_text{
      padding-right: 1rem;
  }
  .expcvv_text2{
      padding:0 1rem;
  }

  .button{
      background: linear-gradient(
  135deg
  , #753370 0%, #298096 100%);
      padding: 15px;
      border: none;
      border-radius: 50px;
      color: white;
      font-weight: 400;
      font-size: 1.2rem;
      margin-top: 10px;
      width:100%;
      letter-spacing: .11rem;
      outline:none;
  }

  .button:hover
  {
    transform: scale(1.05) translateY(-3px);
      box-shadow: 3px 3px 6px #38373785;
  }

  @media only screen and (max-width: 1000px) {
      .card{
          flex-direction: column;
          width: auto;
        
      }

      .leftside{
          width: 100%;
          border-top-right-radius: 0;
          border-bottom-left-radius: 0;
        border-top-right-radius:0;
        border-radius:0;
      }

      .rightside{
          width:auto;
          border-bottom-left-radius: 1.5rem;
          padding:0.5rem 3rem 3rem 2rem;
        border-radius:0;
      }
  }