.dropdown {
  position: relative;
  display: inline-block;
  flex-shrink: 0;
}

.navbar-dropdown-button {
  background-color: #f9f9f9;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0px !important;
  max-width: 200px;

}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content .dropdown-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content .dropdown-item:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.nav-btn-img-one {
  width: 24px;
  height: 24px;
}

.nav-btn-img-two {
  width: 16px;
  height: 16px;
}

/* Mobile responsiveness */
@media (max-width: 480px) {
  .navbar-dropdown-button {
    font-size: 14px;
    padding: 8px;
    gap: 8px;
  }

  .dropdown-content {
    min-width: 120px;
  }

  .dropdown-content .dropdown-item {
    font-size: 14px;
    padding: 10px 14px;
  }

  .nav-btn-img-one {
    width: 20px;
    height: 20px;
  }

  .nav-btn-img-two {
    width: 14px;
    height: 14px;
  }

  .credits-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .credits-button {
    width: 100%;
    margin: 5px 0;
  }
}

/* Mobile responsiveness */
@media (max-width: 480px) {
  .navbar-dropdown-button {
    font-size: 14px;
    padding: 8px;
    gap: 8px;
  }

  .dropdown-content {
    min-width: 120px;
  }

  .dropdown-content .dropdown-item {
    font-size: 14px;
    padding: 10px 14px;
  }

  .nav-btn-img-one {
    width: 20px;
    height: 20px;
  }

  .nav-btn-img-two {
    width: 14px;
    height: 14px;
  }

  .credits-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .credits-button {
    width: 100%;
    margin: 5px 0;
  }
}