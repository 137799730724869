.privacy-policy{
    background-color: #F2F7FF;
  overflow-y: auto;
  width: 100%;
  margin: 0;
  margin-top: 107px;
  height: 1111px;
  padding-left: 20px;
  padding-right: 20px;
}
.privacy-content{
  width: 80%;
}

.privacy-header span{
    font-family: Baumans;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.privacy-content h2 {
  font-size: 20px;
  margin-top: 20px;
  font-family: Roboto;
}

.privacy-content hr {
  border: none;
  height: 1px;
  background-color: #ccc;
  margin-bottom: 20px;
}

.privacy-content p,
.privacy-content ul {
  margin-bottom: 20px;
  font-size: 16px;
  font-family: Roboto;
}

.privacy-content ul {
  list-style-type: disc;
  padding-left: 20px;
}

.privacy-content a {
  color: #007bff;
  text-decoration: none;
}

.privacy-content a:hover {
  text-decoration: underline;
}