.pricing-page {
  background-color: #f2f7ff;
  overflow-y: auto;
  width: 100%;
  margin: 0;
  margin-top: 107px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.pricing-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pricing-header p {
  font-family: Baumans;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
}

.currency-toggle {
  display: flex;
  align-items: center;
}

.toggle-currency {
  background: linear-gradient(92.49deg, #0956CC 0%, #1A4E9F 100%);
  box-shadow: 4px 4px 16px 0px #FFFFFF33 inset, -4px -4px 16px 0px #00000040 inset;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 400;
  border-radius: 24px;
  font-family: Roboto;
  padding: 4px 7px;
  cursor: pointer;
}

.pricing-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 13px;
}

.pricing-card {
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  width: 24%;
  padding: 10px 10px 30px 20px;
  text-align: left;
  color: white;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: white;
}

.pricing-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
}

.pricing-card.subscribed {
  background: linear-gradient(45deg, #2655a0 0%, #4daefd 50%, #185fca 100%);
}

.price-card-title {
  font-size: 1.5em;
  margin-bottom: 10px;
  font-weight: 600;
  font-family: Roboto;
  color: black;
}

.price-card-details {
  font-size: 0.9em;
  color: #4d4d4d;
}

.price-details {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.discounted-price {
  font-size: 1.2em;
  font-weight: 600;
  margin-right: 5px;
  color: black;
}

.actual-price {
  font-size: 1em;
  color: #999;
  text-decoration: line-through;
}

.price-button-container {
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 20px;
}

.price-button {
  background-color: #e6ecfd;
  color: #0956CC;
  font-size: 1em;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 77%;
  transition: background-color 0.3s;
  font-weight: 500;
  font-family: Roboto;
}

.price-button:hover {
  background-color: #f9f9f9;
}

.price-button img {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

.tick-img {
  width: 19px;
  height: 19px;
}

.tick-circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.tier-detail {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.tier-text {
  font-size: 0.9em;
  color: black;
}

.subscribed-title {
  font-size: 1.5em;
  margin-bottom: 10px;
  font-weight: 600;
  font-family: Roboto;
  color: white;
}

.subscribed-details {
  font-size: 0.9em;
  color: #e0e0e0;
}

.subscribed-price {
  font-size: 1.2em;
  font-weight: 600;
  margin-right: 5px;
  color: white;
}

.pricing-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 30px;
}

.pricing-table th,
.pricing-table td {
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
}

.pricing-table th {
  background-color: #cfe2ff;
}

.pricing-table td {
  font-size: 14px;
  background-color: white;
}

.pricing-table th:nth-child(2),
.pricing-table th:nth-child(3),
.pricing-table th:nth-child(4),
.pricing-table th:nth-child(5),
.pricing-table td:nth-child(2),
.pricing-table td:nth-child(3),
.pricing-table td:nth-child(4),
.pricing-table td:nth-child(5) {
  width: 19%;
}

.pricing-table .highlight-basic td:nth-child(3),
.pricing-table .highlight-standard td:nth-child(4) {
  background-color: #ffe4b3;
}

.pricing-table .highlight-enterprise td:nth-child(5) {
  background-color: #d3e0dc;
}

/* Media queries for mobile responsiveness */
@media (max-width: 768px) {
  .pricing-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .pricing-cards {
    flex-direction: column;
    align-items: center;
  }

  .pricing-card {
    width: 90%;
    margin-bottom: 20px;
  }

  .price-button-container {
    justify-content: flex-start;
  }

  .pricing-table th,
  .pricing-table td {
    padding: 8px;
    font-size: 12px;
  }

  .pricing-table th:nth-child(2),
  .pricing-table th:nth-child(3),
  .pricing-table th:nth-child(4),
  .pricing-table th:nth-child(5),
  .pricing-table td:nth-child(2),
  .pricing-table td:nth-child(3),
  .pricing-table td:nth-child(4),
  .pricing-table td:nth-child(5) {
    width: 25%;
  }
}
