.contentsss {
    z-index: 9999;
}
.secure, .backBtn {
    display: flex;
}
.secure span, .backBtn span {
    margin-left: 5px;
}
.backBtn {
    margin-top: 20px;
}
.secure {
    color: #afb5c0;
    align-items: flex-end;
}
.secure .icon {
    font-size: 20px;
    line-height: 20px;
}

.dashboard {
  overflow-y: auto;
  width: 100%;
  margin: 0;
  margin-top: 107px; 
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.details {
    max-width: 800px;
    min-height: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -200px;
    background: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    position: relative;
}
.details__item {
    display: flex;
}
.details__user {
    background: #f6f9fc;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #dadada;
}
.item__quantity {
    position: absolute;
    right: 50px;
    top: 30px;
    font-size: 20px;
}
.item__image {
    display: flex;
    align-items: center;
    justify-content: center;
}
.item__image .iphone {
    margin-top: -50px;
    margin-left: -100px;
    width: 200px;
}
.item__details {
    padding: 30px;
}
.item__title {
    font-size: 28px;
    font-weight: 600;
}
.item__price {
    font-size: 22px;
    color: #bec3cb;
}
.icon {
    font-size: 16px;
    vertical-align: middle;
}

.navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 50px 0 80px 0;
    color: #246eea;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
}
.containering {
    width: 960px;
    margin: 0 auto;
}
.shadow {
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
}
.row {
    display: flex;
}
.txt {
    border-color: #e1e8ee;
    width: 100%;
}
.input {
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    height: 48px;
    padding-left: 15px;
    font-weight: 600;
    font-size: 14px;
    color: #5e6977;
}

.text-validated {
    border-color: #7dc855;
   
    background-repeat: no-repeat;
    background-position: right 18px center;
}
.ddl {
    border-color: #f0f4f7;
    background-color: #f0f4f7;
    width: 100px;
    margin-right: 10px;
}
.title {
    font-size: 14px;
    padding-bottom: 8px;
}
.field {
    padding-top: 15px;
    padding-right: 30px;
    width: 50%;
}
.field.small {
    width: auto;
}
.notification {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    display: flex;
    justify-content: center;
}
.notification .icon {
    font-size: 28px;
    color: #7dc855;
    line-height: 28px;
    margin-right: 10px;
}
.actions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 60px 0 40px 0;
}
.payment {
    padding: 20px 0 0 0;
}
.payment__title {
    margin: 40px 0 20px 0;
    font-size: 18px;
    display: flex;
    text-align: left;
}
.payment__title .icon {
    margin-right: 10px;
}
.btn {
    font-family: "Josefin Sans", sans-serif;
    border-radius: 8px;
    border: 0;
    letter-spacing: 1px;
    color: #fff;
    background: #246eea;
    padding: 20px 60px;
    white-space: nowrap;
    font-size: 16px;
    line-height: 1;
    text-transform: uppercase;
    transition: all 0.15s ease;
    text-decoration: none;
}
.btn .icon {
    margin-left: 10px;
    font-size: 20px;
}
.btn:hover {
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
    background: #4984ea;
}
.btn.action__back {
    background: transparent;
    color: #a0a0a0;
}
.payment__types {
    display: flex;
    justify-content: center;
    align-items: center;
}
.payment__info {
    display: flex;
}
.payment__cc {
    flex: 60%;
}
.payment__shipping {
    flex: 40%;
}
.shipping__info {
    background: #f6f9fc;
    padding: 10px;
    border-radius: 8px;
}
.payment__type {
    display: flex;
    border: 2px solid #d9d9d9;
    border-radius: 8px;
    padding: 20px 40px;
    width: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #d9d9d9;
    transition: all 0.15s ease;
}
.payment__type:hover {
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
}
.payment__type.active {
    color: #0a0a0a;
    background: #f6fcf7;
    border-color: #7dc855;
}
.payment__type .icon {
    font-size: 32px;
    margin-right: 10px;
}
.payment__type + .payment__type {
    margin-left: 10px;
}
.icon-xl {
    font-size: 48px;
    line-height: 48px;
}
.contentsss {
    display: block;
}
.thankyou {
    display: block;
}
.thankyou .details {
    opacity: 1;
    justify-content: center;
    align-items: center;
}
.thankyou .details h3 {
    font-weight: 600;
}
.thankyou .details__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.thankyou .details__item .icon-xl {
    font-size: 32px;
    color: #7dc855;
    line-height: 32px;
}
.loading {
    position: relative;
}
.loading:after {
    -webkit-animation: spinAround 0.5s infinite linear;
    animation: spinAround 0.5s infinite linear;
    border: 4px solid #7dc855;
    border-radius: 290486px;
    border-right-color: transparent;
    border-top-color: transparent;
    content: "";
    height: 5em;
    width: 5em;
    position: absolute;
}
.loading .details__item {
    opacity: 0;
}
@-webkit-keyframes spinAround {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0);
   }
    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
   }
}
@keyframes spinAround {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0);
   }
    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
   }
}