/* Global Styles */
.no-products-message {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  color: #555;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.overlay-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.add-products-btn-overlay {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 4px;
}

/* Products Page Styles */
.products {
  background-color: #f2f7ff;
  overflow-y: auto;
  width: 100%;
  margin: 0;
  margin-top: 107px;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.products-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Allows wrapping on small screens */
  margin-top: 40px; /* Added margin */
}

.products-text {
  font-family: Baumans;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
}

.add-products-btn {
  display: flex;
  align-items: center;
  background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
  box-shadow: 4px 4px 16px 0px #ffffff33 inset,
    -4px -4px 16px 0px #00000040 inset;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  font-family: Roboto;
  margin: 0px !important;
}

.add-icon {
  width: 19px;
  margin-right: 5px;
}

.product-cards {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
}

.product-card {
  width: calc(25% - 20px);
  aspect-ratio: 281 / 317;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 16px;
  padding: 7px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.product-card p {
  margin-top: 10px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  text-align: center;
}

.product-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-name {
  font-family: Roboto;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  letter-spacing: 0.02em;
  color: #54555c;
  margin-left: 5px;
}

.small-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
  border: 0.4px solid #0956cc;
  bottom: 3px;
  right: 4px;
  position: relative;
}

.small-icon {
  width: 70%;
  height: auto;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 7px;
}

.product-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Hide any overflow */
  border-radius: 7px; /* Apply border radius to the container */
  width: 100%;
  height: 100%;
}

.product-card {
  position: relative;
}

.product-dropdown-menu {
  display: none; /* Initially hidden */
  position: absolute;
  right: 5px;
  top: 30px;
  background-color: #f9f9f9;
  min-width: 80px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.product-dropdown-menu.show {
  display: block; /* Show the menu */
}

.product-dropdown-menu p {
  color: black;
  text-decoration: none;
  display: block;
  margin: 0px;
  padding: 10px;
}

.product-dropdown-menu p:hover {
  background-color: #f1f1f1;
}

.product-table {
  margin-top: 30px;
  overflow-x: auto;
}

.product-table table {
  border-collapse: collapse;
  width: 100%;
}

.product-table th,
.product-table td {
  border-bottom: 1px solid #0956cc;
  text-align: left;
}

.product-table th {
  border-top: 1px solid #0956cc;
}

.product-table td {
  border-bottom: 0.8px solid #0956cc;
  padding: 11px 11px 11px 0px;
}

.product-table th {
  background-color: transparent;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #0956cc;
  padding-top: 5px;
  padding-bottom: 5px;
}

.product-table th:last-child {
  text-align: right;
}

.product-table td:last-child {
  text-align: right;
}

.product-table-first {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
}

.product-request-button {
  border-radius: 24px;
  border: 1px solid #0956cc;
  background-color: transparent;
  padding: 4px 7px;
  cursor: pointer;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  letter-spacing: 0.02em;
  color: #0956cc;
}

/* Media Queries for Mobile Optimization */
@media (max-width: 767px) {
  .products {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 90px;
  }

  .products-header {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 85px; /* Adjusted margin for mobile */
  }

  .products-text {
    font-size: 18px;
  }

  .add-products-btn {
    padding: 8px 16px;
    font-size: 12px;
  }

  .product-cards {
    flex-direction: column;
  }

  .product-card {
    width: 100%;
    margin: 10px 0;
  }

  .product-name {
    font-size: 12px;
  }

  .product-table table {
    font-size: 12px;
  }

  .product-table th,
  .product-table td {
    padding: 8px;
  }

  .product-request-button {
    font-size: 12px;
    padding: 2px 4px;
  }
}
