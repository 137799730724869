.signup-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.inputStyle{
  width: 57% !important;
  height: 66px;
  border-radius: 7px;
  border: 0px;
  margin-right: 8px;
  background: #e2eeff;
  font-size: 20px;
}


.login-image-center {
  position: relative;
  width: fit-content;
  margin: auto; 
}

.login-background-image {
  position: absolute;
  top: -70px;
  right: 140px;
  z-index: 1;
  width: 100%; 
  height: auto; 
}

.login-base-image {
  display: block;
  position: relative;
  z-index: 2;
}

.login-overlay-image {
  position: absolute;
  top: 70px; 
  left: 267px;
  width: 90px; 
  z-index: 3;
  height: 197px;
  border-radius: 10px;
}



.login-image-center {
  height: 27em;
  position: relative;
  left: -50px;
}
@media (min-width: 768px) {
  .signup-container {
    flex-direction: row;
  }
}
.first-name-container label,
.last-name-container label {
  margin-bottom: 10px; /* Add margin-bottom to the labels */
}

/* Add to your existing CSS */

.otp-validation-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 20px;
}

.otp-input-container {
  width: 100%;
  max-width: 500px;
}

@media (max-width: 767px) {
  .otp-validation-container {
    padding: 20px;
  }

  .otp-input-container {
    width: 100%;
  }

  .inputStyle{
    width: 57% !important;
    height: 40px;
    border-radius: 7px;
    border: 0px;
    margin-right: 8px;
    background: #e2eeff;
    font-size: 20px;
  }
  .signup-welcome {
    font-size: 24px;
    text-align: center;
  }

  .signup-arvocloud {
    font-size: 32px;
    text-align: center;
  }

  .signup-button,
  .signup-button.inactive {
    width: 100%;
    font-size: 16px;
    height: 48px;
  }

  .otp-info-text {
    font-size: 10px;
    text-align: center;
  }
}

.name-inputs-container {
  display: flex;
  justify-content: space-between;
}

.first-name-container,
.last-name-container {
  width: calc(50% - 5px); /* Adjust width as needed */
}

.first-name-container input,
.last-name-container input {
  width: 100%;
}

@media (max-width: 767px) {
  .name-inputs-container {
    flex-direction: column;
  }

  .first-name-container,
  .last-name-container {
    width: 100%;
  }

  .first-name-container input,
  .last-name-container input {
    width: 100%;
  }
}


.left-side {
  flex-basis: 55%;
  height: 100vh;
  background-color: #e2eeff;
  clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);
  display: flex;
  justify-content: center;
  position: relative;
}

.image-center {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-image-center {
  height: 27em;
  position: relative;
  left: -50px;
}

.logo-bottom-left {
  align-self: flex-start;
}

.logo-bottom-left img {
  width: 100px; /* Adjust the size as needed */
}

.right-side {
  flex-basis: 40%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup-right-side {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 20px;
}

.signup-welcome {
  font-family: Roboto;
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
}

.signup-top-texts {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.signup-arvocloud {
  font-family: Baumans;
  font-size: 48px;
  font-weight: 400;
  line-height: 57px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #0956cc;
}

.signup-container-input-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
  width: 100%;
}

.box-signup-input {
  height: 56px;
  width: 100%;
  border: 2px solid #9da1b8;
  border-radius: 12px;
  padding-left: 13px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #54555c;
}

.signup-button {
  background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
  box-shadow: 4px 4px 16px 0px #ffffff33 inset, -4px -4px 16px 0px #00000040 inset;
  width: 100%;
  height: 57px;
  padding: 16px;
  border-radius: 12px;
  opacity: 1;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-family: Roboto;
  margin-top: 18px;
}

.signup-button.inactive {
  opacity: 0.5;
}

.text-btn-below {
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.having-trb {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.02em;
  margin-right: 5px;
  opacity: 0.57;
}

.contact-us {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #0956cc;
}

.password-requirements {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
  font-size: 14px;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

/* Media queries for responsiveness */
@media (max-width: 767px) {
  .left-side {
    display: none;
  }

  .right-side {
    flex-basis: 100%;
    height: auto;
    padding: 20px;
  }

  .signup-welcome {
    font-size: 24px;
    text-align: center;
  }

  .signup-arvocloud {
    font-size: 32px;
    text-align: center;
  }

  .box-signup-input {
    width: 100%;
    font-size: 16px;
    height: 48px;
  }

  .signup-button,
  .signup-button.inactive {
    width: 100%;
    font-size: 16px;
    height: 48px;
  }

  .password-requirements {
    font-size: 12px;
  }

  .otp-info-text {
    font-size: 10px;
  }

  .first-last-name-container {
    display: flex;
    flex-direction: column;
  }

  .first-last-name-container label {
    margin-top: 10px;
  }
}
