.open-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 30px;
  }
  
  .open-table td {
    border-bottom: 0.5px solid #0956cc;
    padding: 8px;
    text-align: left;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    line-height: 18.75px;
    letter-spacing: 0.02em;
    color: #54555c;
    padding: 10px 0;
    padding-top: 11.5px;
    padding-bottom: 11.5px;
  }
  
  .open-table th {
    border-top: 1px solid #0956cc;
    border-bottom: 1px solid #0956cc;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    letter-spacing: 0.02em;
    color: #0956cc;
    padding-top: 4px;
    padding-bottom: 4px;
    text-align: left;
  }