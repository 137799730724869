.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F2F8FF80;
  position: fixed;
  top: 0;
  z-index: 999900;
  height: auto;
  padding: 0 20px;
  margin-top: 20px;
  width: calc(100% - 20%);
  left: 20%;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
}

.credits {
  font-size: 16px;
}

.credits p {
  margin: 0;
}

.credit-icon {
  width: 20px;
  height: 20px;
  position: relative;
}

.bar {
  width: 4px;
  height: 20px;
  background-color: gold;
  position: absolute;
}

.bar:nth-child(1) {
  left: 0;
}

.bar:nth-child(2) {
  left: 6px;
}

.bar:nth-child(3) {
  left: 12px;
}

.bar:nth-child(4) {
  left: 18px;
}

/* Media queries for tablet */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    width: 100%;
    left: 0;
    height: auto;
  }

  .navbar-brand,
  .navbar-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navbar-brand {
    margin-bottom: 10px;
  }
}

/* Media queries for mobile */
@media (max-width: 480px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    width: 100%;
    left: 0;
    height: auto;
  }

  .navbar-brand,
  .navbar-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-brand {
    margin-bottom: 10px;
  }

  .credits {
    font-size: 14px;
  }

  .credit-icon {
    width: 18px;
    height: 18px;
  }

  .bar {
    width: 3px;
    height: 18px;
  }

  .bar:nth-child(2) {
    left: 5px;
  }

  .bar:nth-child(3) {
    left: 10px;
  }

  .bar:nth-child(4) {
    left: 15px;
  }

  .credits-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .credits-button {
    width: 100%;
    margin: 5px 0;
    font-size: 14px; /* Adjust font size for mobile */
    padding: 8px; /* Adjust padding for mobile */
  }

  .credits-button p {
    font-size: 12px; /* Reduce font size for Credits text */
  }

  .credits-button div {
    font-size: 14px; /* Reduce font size for credits value */
  }

  .credits-button svg {
    width: 20px; /* Reduce icon size */
    height: 20px; /* Reduce icon size */
  }
}
