.tab-with-count {
    display: flex;
    align-items: center;
  }
  
  .count-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: white;
    font-size: 12px;
    margin-left: 4px;
  }