.review-details {
  margin-top: 10px;
  padding: 10px;
  border-top: 1px solid #ccc;
}


.review-city {
  font-weight: bold;
  margin-bottom: 5px;
}

.sentiment-analysis {
  margin-top: 10px;
}

.sentiment-analysis ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sentiment-analysis ul li {
  margin-bottom: 5px;
}


.react-tabs__tab-list {
  display: flex;
  border: 1px solid #0956CC !important; 
  border-radius: 20px; 
  overflow: hidden;
  font-size: 15px;
text-align: center;
}

.react-tabs__tab {
  cursor: pointer;
  background-color: transparent;
  width: 160px;
  margin: 2px !important;
  margin-top: 1px !important;
  margin-bottom: 3px !important;
  font-family: Roboto;
font-weight: 700;
color: #0956CC;
}

.react-tabs__tab:last-child {
  border-right: none;
}

.react-tabs__tab--selected {
  background: linear-gradient(92.49deg, #0956CC 0%, #1A4E9F 100%) !important;
  color: #fff !important;
  box-shadow: 
    4px 4px 16px 0px #FFFFFF33 inset, 
    -4px -4px 16px 0px #00000040 inset, 
    0px 4px 4px 0px #00000040; 
    border-radius: 20px !important;
    outline: none !important;
    font-family: Roboto;
font-weight: 700;

  }

  .react-tabs__tab:focus:after{
    background: transparent !important;
  }



.tag-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Baumans;
}

.tag-page h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    font-family: Baumans;
}

.description-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.description {
    width: 60%;
}

.key-features h3 {
    font-size: 18px;
    margin-bottom: 5px;
}

.key-features ul {
    list-style-type: none;
    padding: 0;
}

.key-features li {
    margin-bottom: 5px;
}

.photo-container {
    width: 40%;
}

.photo-container img {
    max-width: 100%;
    height: auto;
    display: block;
    margin-bottom: 10px;
}

.attached-uid {
    margin-top: 20px;
}

.attached-uid h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.attached-uid ul {
    list-style-type: none;
    padding: 0;
}

.attached-uid li {
    margin-bottom: 5px;
}


.popup {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999999;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Shadow effect */
  }
  
  .popup button {
    display: block;
    margin-top: 10px;
    background-color: #ccc;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .popup button:hover {
    background-color: #ddd;
  }
  
  .productdetails {
    background-color: #e4edfa;
    overflow-y: auto;
    width: 100%;
    margin: 0;
    margin-top: 107px;
    height: 1111px;
    padding-left: 20px;
    padding-right: 20px;
  }
  /* e2eeff */
  .productdetails-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .productdetails-text {
    font-family: Baumans;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
  }
  
  .product-details-content {
    display: flex;
    justify-content: space-between;
  }
  
  .product-details-product-name {
    font-family: Roboto;
    font-size: 29px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #54555c;
  }
  .product-details-product-info {
    font-family: Roboto;
    font-size: 17px;
    font-weight: 400;
    line-height: 23.44px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #54555c;
  }
  .product-details-container-info {
    width: 570px;
  }
  .product-details-container-price {
    margin-top: 60px;
  }


  .vertical-line {
    border-left: 10px solid black; /* Adjust border style and color as needed */
    height: 100%; /* Ensure the line stretches to match the height of the container */
  }
  
  .product-details-unitprice {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #54555c;
  }
  
  .product-details-price {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #54555c;
    margin-left: 5px;
  }
  .product-details-button-container{
    margin-top: 40px;
  }
  .product-details-buttons-left {
    width: 126px;
    height: 34px;
    padding: 10px 20px;
    border-radius: 24px;
    background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
    box-shadow: 4px 4px 16px 0px #ffffff33 inset,
      -4px -4px 16px 0px #00000040 inset;
    border: 0px;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
    cursor: pointer;
  }
  
  .product-details-buttons-right {
    width: 161px;
    height: 34px;
    padding: 10px 20px;
    border-radius: 24px;
    background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
    box-shadow: 4px 4px 16px 0px #ffffff33 inset,
      -4px -4px 16px 0px #00000040 inset;
    border: 0px;
    margin-left: 20px;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
    cursor: pointer;
  }
  .product-details-right {
    position: relative; /* This allows for absolute positioning inside */
    padding-top: 50px; /* Make room for the dots */
  }
  
  .three-dots {
    position: absolute; 
    top: 0; 
    right: 0;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 0.2px solid #0956CC;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10; /* Ensure it's above other content */
  }
  
  .dots{
    height: 20px;
    width: 20px;
  }
  .css{
  
    /* aspect-ratio: 3/2; */
    object-fit: contain;
    /* mix-blend-mode: color-burn; */
  }
  
  .product-images-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto;
    gap: 6px;
  }
  
  .grid-item img {
    width: 100%;
    height: auto;
    display: block;
    z-index: 888;
    border-radius: 8px;
  }
  /* Add this CSS to make the images square */
  .product-images-grid .grid-item img {
    aspect-ratio: 1/1; /* Make images square */
  }
  
  /* Adjust the main image to maintain its aspect ratio */
  .main-image img {
    aspect-ratio: 1/1; /* Assuming aspect ratio of 3:2 for main image */
  }
  
  .main-image {
    grid-column: 1 / -1;
    grid-row: 1;
    object-fit:contain ;
  }
  
  .main-image img {
    max-height: 300px;
    object-fit: cover;
    z-index: 8888888;
    /* mix-blend-mode: color-burn; */
  }
  
  .small-image {
    grid-column: span 1;
    grid-row: 2;
  }
  
  .small-image img {
    max-height: 90px;
    object-fit: cover;
    z-index: 888;
    /* mix-blend-mode: color-burn; */
  }
  
  .above-table-texts{
    display: flex;
    justify-content: space-between;
    width: 50%;
    flex-direction: row;
    align-items: center;
  }
  
  .product-details-recent-scans{
    font-family: Baumans;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555C;
  }
  
  .product-details-last-updated{
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #54555C;
  margin-right: 6px;
  }
  .product-details-reload-img{
    margin-right: 6px;
  }
  
  .print-button {
    display: flex;
    align-items: center;
    background-color: inherit;
    padding: 5px 12px;
    cursor: pointer;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #0956CC;
    border-radius: 30px;
  }
  
  .button-print-img {
    margin-left: 6px; /* Space between the text and the image */
    width: 14px; /* Adjust width as needed */
    height: 14px; /* Adjust height as needed */
  }
  .product-details-text-right{
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product-details-table {
    width: 50%;
    border-collapse: collapse;
    margin-top: 20px;
    table-layout: fixed; /* Ensures that the columns are evenly distributed */
  }
  
  .product-details-table thead th {
    background-color: inherit; /* Light grey background for the header */
    padding: 3px; /* Provides space within the cells */
    text-align: left; /* Aligns text to the left */
    border: none;
    border-top: 1px solid #0956CC; /* Blue top border */
    border-bottom: 1px solid #0956CC; /* Blue bottom border */
    color: #0956CC;
    font-weight: 400;
    font-size: 16px;
  }
  
  /* Styles for tbody to remove borders */
  .product-details-table tbody tr td {
    border: none;
    padding: 8px; 
    text-align: left;
    background-color: #e4edfa;
    font-size: 13px;
    color: #54555C;
  }
  
  .table-and-map {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Align items at the top */
    margin-top: 10px;
    margin-bottom: 30px;
  }
  
  
  .table-container {
    flex: 1;
  }
  
  .product-details-map-container {
    flex: 1;
    display: flex;
    justify-content: center; /* Center the map in the container */
    align-items: center; /* Center the map vertically */
  }
  
  .table-and-map img {
    max-width: 100%; 
    max-height: 350px; 
    position: relative;
    left: 40px;
  }
  
  .product-details-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    table-layout: fixed;
  }
  

  .review-page {
    padding: 5px;
  }
  
  .review-container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .review-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .review-stats {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .star-rating-container {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  
  .star-icon {
    color: #ffd700;
    font-size: 24px;
    margin-right: 5px;
  }
  
  .star-rating-bar {
    background-color: #ddd;
    height: 20px;
    width: 100%;
    border-radius: 4px;
  }
  
  .recent-reviews {
    margin-top: 20px;
    height: 80%;
    overflow: scroll;
  }
  
  .review-list {
    max-height: 400px;
    overflow-y: auto;
  }
  
  .review-card {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .review-user {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .user-info {
    display: flex;
    flex-direction: column;
  }
  
  .user-name {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .user-rating {
    color: #666;
  }
  
  .review-text {
    color: #333;
  }
  
  .share-thoughts {
    margin-top: 20px;
  }
  
  .write-review-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .write-review-btn:hover {
    background-color: #0056b3;
  }
  
  
  
  

  .progress-bar-container {
    width: 100%;
    height: 20px; /* Adjust height as needed */
    background-color: #f0f0f0; /* Background color of the progress bar container */
    border-radius: 10px; /* Rounded corners */
    overflow: hidden; /* Ensure inner content does not overflow */
  }
  
  .progress-bar {
    height: 100%;
    background-image: linear-gradient(to right, gold, orange); /* Golden linear gradient */
    transition: width 0.3s ease; /* Smooth transition for width changes */
  }
  
  .progress-bar-inner {
    height: 100%;
    border-radius: 10px; /* Rounded corners */
  }


  /* styles.css */
.awesome-component {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align from start to end */
  align-items: stretch; /* Stretch items to equal size */
}

.top-section,
.bottom-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align items from start */
  margin-bottom: 20px;
}

.chart-container {
  display: flex;
  align-items: center;
}

.average-rating,
.reviews-by-directory,
.replies-and-breakdown,
.response-time,
.ratings-and-reviews,
.avg-rating-trend,
.big-bottom-component {
  flex: 1;
  padding: 20px;
  border-radius: 8px;
  margin: 10px;
  background-color: #fff;
  border-radius: 10px;
  padding: 1em;
  height: 280px;
  position: relative;
  border: 0.2px solid rgb(192, 209, 236);
  /* x */
}


.heading-label {
  font-family: Baumans;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
}

.line-chart,
.pie-chart,
.pointers,
.response-time,
.ratings-and-reviews,
.avg-rating-trend {
  flex: 1;
  padding: 10px;
  border-radius: 8px;
  margin: 10px;
}

.donut-chart{
  flex: 1;
}

.pointer {
  background-color: #007bff;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 5px;
}

.big-bottom-component {
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
}



.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; 

    overflow-y: auto; 
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
} 