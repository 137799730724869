.pie-chart-container {
    width: 50%;
    background-color: white;
    margin-top: 20px;
    border-radius: 20px;
    overflow: hidden;
    padding: 10px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.pie-chart-content {
    width: 100%; 
    height: auto;
    overflow: auto;
    background-color: white;
    border-radius: 20px;

  }
  