.App {
  width: 100vw; 
}

.App:hover {
  cursor: default;
}

/* Hide horizontal scrollbar for .App and its children */
.App, .App * {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.App::-webkit-scrollbar, .App *::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.content {
  margin-left: 20%;
  width: 80%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100vh;
}

.blur-free-tier-content {
  position: relative;
  overflow: hidden;
}

.blur-free-tier-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  z-index: 1000;
}

.blur-free-tier-content::after {
  content: 'Upgrade your plan 🔒'; 
  white-space: nowrap; /* Prevent line break */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(13, 26, 163); /* adjust the text color as needed */
  font-size: 15px; /* adjust the font size as needed */
  text-align: center;
  z-index: 10000;
}

.blur-free-tier-content-tabs {
  position: relative;
  overflow: hidden;
}

.blur-free-tier-content-tabs::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  z-index: 10000;
}

.blur-free-tier-content-tabs::after {
  content: 'Upgrade your plan 🔒'; 
  white-space: nowrap; /* Prevent line break */
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(13, 26, 163); /* adjust the text color as needed */
  font-size: 15px; /* adjust the font size as needed */
  text-align: center;
  z-index: 1000000000000000000;
}

.blur-free-tier-content-alts {
  position: relative;
  overflow: hidden;
}

.blur-free-tier-content-alts::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(6px);
  z-index: 10000;
}

.blur-free-tier-content-alts::after {
  content: 'Click Here to Know More 🔒'; 
  white-space: nowrap; /* Prevent line break */
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(13, 26, 163); /* adjust the text color as needed */
  font-size: 15px; /* adjust the font size as needed */
  text-align: center;
  z-index: 10000000;
}

.chat-support-chat-img {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background: transparent;
  z-index: 999999999;
}

.blur-content-textarea {
  pointer-events: none; /* Disables mouse events on the textarea */
  filter: blur(1px); /* Adds blur effect to the textarea */
  cursor: not-allowed; 
}

.chatbot-container {
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 9999999999999999999999;
}

.options-container {
  display: flex;
  flex-direction: column;
}

.option-button {
  padding: 10px;
  margin: 5px 0;
  border: none;
  border-radius: 5px;
  background-color: #376b7e;
  color: white;
  cursor: pointer;
}

.option-button:hover {
  background-color: #295362;
}

.Toastify__toast-container{
  z-index: 9999999999999999 !important;
}

/* Media queries for mobile screens */
@media (max-width: 768px) {
  .content {
    margin-left: 0;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .content {
    margin-left: 0;
    width: 100%;
  }
}
