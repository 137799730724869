
.description-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.description {
    width: 60%;
}

.key-features h3 {
    font-size: 18px;
    margin-bottom: 5px;
}

.key-features ul {
    list-style-type: none;
    padding: 0;
}

.key-features li {
    margin-bottom: 5px;
}

.photo-container {
    width: 40%;
}

.photo-container img {
    max-width: 100%;
    height: auto;
    display: block;
    margin-bottom: 10px;
}

.attached-uid {
    margin-top: 20px;
}

.attached-uid h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.attached-uid ul {
    list-style-type: none;
    padding: 0;
}

.attached-uid li {
    margin-bottom: 5px;
}


.popup {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999999;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Shadow effect */
  }
  
  .popup button {
    display: block;
    margin-top: 10px;
    background-color: #ccc;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .popup button:hover {
    background-color: #ddd;
  }
  
  .productdetails {
    background-color: #e4edfa;
    overflow-y: auto;
    width: 100%;
    margin: 0;
    margin-top: 107px;
    height: 1111px;
    padding-left: 20px;
    padding-right: 20px;
  }
  /* e2eeff */
  .productdetails-headers {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .productdetails-text {
    font-family: Baumans;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
  }
  
  .product-details-content {
    display: flex;
    justify-content: space-between;
  }
  
  .product-details-product-name {
    font-family: Roboto;
    font-size: 29px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #54555c;
  }
  .product-details-product-info {
    font-family: Roboto;
    font-size: 17px;
    font-weight: 400;
    line-height: 23.44px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #54555c;
  }
  .product-details-container-info {
    width: 570px;
  }
  .product-details-container-price {
    margin-top: 60px;
  }


  .vertical-line {
    border-left: 10px solid black; /* Adjust border style and color as needed */
    height: 100%; /* Ensure the line stretches to match the height of the container */
  }
  
  .product-details-unitprice {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #54555c;
  }
  
  .product-details-price {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #54555c;
    margin-left: 5px;
  }
  .product-details-button-container{
    margin-top: 40px;
  }
  .product-details-buttons-left {
    width: 126px;
    height: 34px;
    padding: 10px 20px;
    border-radius: 24px;
    background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
    box-shadow: 4px 4px 16px 0px #ffffff33 inset,
      -4px -4px 16px 0px #00000040 inset;
    border: 0px;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
    cursor: pointer;
  }
  
  .product-details-buttons-right {
    width: 161px;
    height: 34px;
    padding: 10px 20px;
    border-radius: 24px;
    background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
    box-shadow: 4px 4px 16px 0px #ffffff33 inset,
      -4px -4px 16px 0px #00000040 inset;
    border: 0px;
    margin-left: 20px;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
    cursor: pointer;
  }
  .product-details-right {
    position: relative; /* This allows for absolute positioning inside */
    padding-top: 50px; /* Make room for the dots */
  }
  
  .three-dots {
    position: absolute; 
    top: 0; 
    right: 0;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 0.2px solid #0956CC;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10; /* Ensure it's above other content */
  }
  
  .dots{
    height: 20px;
    width: 20px;
  }
  .css{
  
    /* aspect-ratio: 3/2; */
    object-fit: contain;
    /* mix-blend-mode: color-burn; */
  }
  
  .product-images-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto;
    gap: 6px;
  }
  
  .grid-item img {
    width: 100%;
    height: auto;
    display: block;
    z-index: 888;
    border-radius: 8px;
  }
  /* Add this CSS to make the images square */
  .product-images-grid .grid-item img {
    aspect-ratio: 1/1; /* Make images square */
  }
  
  /* Adjust the main image to maintain its aspect ratio */
  .main-image img {
    aspect-ratio: 1/1; /* Assuming aspect ratio of 3:2 for main image */
  }
  
  .main-image {
    grid-column: 1 / -1;
    grid-row: 1;
    object-fit:contain ;
  }
  
  .main-image img {
    max-height: 300px;
    object-fit: cover;
    z-index: 8888888;
    /* mix-blend-mode: color-burn; */
  }
  
  .small-image {
    grid-column: span 1;
    grid-row: 2;
  }
  
  .small-image img {
    max-height: 90px;
    object-fit: cover;
    z-index: 888;
    /* mix-blend-mode: color-burn; */
  }
  
  .above-table-texts{
    display: flex;
    justify-content: space-between;
    width: 50%;
    flex-direction: row;
    align-items: center;
  }
  
  .product-details-recent-scans{
    font-family: Baumans;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555C;
  }
  
  .product-details-last-updated{
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #54555C;
  margin-right: 6px;
  }
  .product-details-reload-img{
    margin-right: 6px;
  }
  
  .print-button {
    display: flex;
    align-items: center;
    background-color: inherit;
    padding: 5px 12px;
    cursor: pointer;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #0956CC;
    border-radius: 30px;
  }
  
  .button-print-img {
    margin-left: 6px; /* Space between the text and the image */
    width: 14px; /* Adjust width as needed */
    height: 14px; /* Adjust height as needed */
  }
  .product-details-text-right{
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product-details-table {
    width: 50%;
    border-collapse: collapse;
    margin-top: 20px;
    table-layout: fixed; /* Ensures that the columns are evenly distributed */
  }
  
  .product-details-table thead th {
    background-color: inherit; /* Light grey background for the header */
    padding: 3px; /* Provides space within the cells */
    text-align: left; /* Aligns text to the left */
    border: none;
    border-top: 1px solid #0956CC; /* Blue top border */
    border-bottom: 1px solid #0956CC; /* Blue bottom border */
    color: #0956CC;
    font-weight: 400;
    font-size: 16px;
  }
  
  /* Styles for tbody to remove borders */
  .product-details-table tbody tr td {
    border: none;
    padding: 8px; 
    text-align: left;
    background-color: #e4edfa;
    font-size: 13px;
    color: #54555C;
  }
  
  .table-and-map {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Align items at the top */
    margin-top: 10px;
    margin-bottom: 30px;
  }
  
  
  .table-container {
    flex: 1;
  }
  
  .product-details-map-container {
    flex: 1;
    display: flex;
    justify-content: center; /* Center the map in the container */
    align-items: center; /* Center the map vertically */
  }
  
  .table-and-map img {
    max-width: 100%; 
    max-height: 350px; 
    position: relative;
    left: 40px;
  }
  
  .product-details-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    table-layout: fixed;
  }
  
  
  
  