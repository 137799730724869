.revenue-font-main{
    font-family: Roboto;
  font-size: 3.4rem;
  font-weight: 200;
  letter-spacing: 0.02em;
  color: #54555c;
  
}
.revenue-percent{
  font-family: Roboto;
  font-size: 12px;
  font-weight: 300;
  margin-left: 3px;
}

.revenue-past-timeline{
font-family: Roboto;
font-size: 10px;
font-weight: 400;
color: #7a7a7a;
}
.revenue-header{
  font-family: Baumans;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.02em;
    text-align: left;
}