.cin-selector-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    border: 1px solid #ddd;
    border-radius: 8px;
    max-width: 800px;
    margin: 20px auto;
    background-color: #f9f9f9;
  }
  
  .cin-selector-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .cin-selector-dropdown-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
  }
  
  .cin-selector-dropdown {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
  }
  
  .cin-selector-label {
    font-size: 14px;
    margin-bottom: 5px;
    color: #555;
  }
  
  .cin-selector-input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .cin-selector-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
    z-index: 10;
  }
  
  .cin-selector-dropdown-item {
    padding: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .cin-selector-dropdown-item:hover {
    background-color: #f0f0f0;
  }
  
  .cin-selector-select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
  }
  
  .cin-selector-quantity-input {
    width: 100%;
    margin-bottom: 20px;
  }
  .cin-selector-dropdown-menu {
    max-height: 200px; /* Set maximum height for the dropdown */
    overflow-y: auto; /* Enable vertical scrolling */
    -webkit-overflow-scrolling: touch; /* Ensure smooth scrolling on macOS */
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.2); /* For Firefox */
  }
  
  .cin-selector-dropdown-menu::-webkit-scrollbar {
    width: 8px; /* Set width for the scrollbar */
  }
  
  .cin-selector-dropdown-menu::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5); /* Set thumb color */
    border-radius: 4px;
  }
  
  .cin-selector-dropdown-menu::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1); /* Set track color */
  }
  
  
  .cin-selector-download-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: linear-gradient(92.49deg, #0956CC 0%, #1A4E9F 100%);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cin-selector-download-button:hover {
    background-color: #45a049;
  }
  