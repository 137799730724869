.dcode-container {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    padding: 20px;
  }
  
  .dcode-card {
    background-color: white;
    border-radius: 11px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 16px;
    width: 25%; /* Adjust width as needed to fit four cards in a row */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 200px;
  }
  
  .dcode-card-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .dcode-card-img {
    width: 19px;
    margin-right: 10px;
  }
  
  .dcode-card-title {
    font-family: 'Baumans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.02em;
  }
  
  .dcode-card-content {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #7a7a7a;
  }
  
  .dcode-card-subtitle {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 300;
    margin-top: 10px;
    color: #555;
  }
  