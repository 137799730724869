.forms {
  background-color: #F2F7FF;
  overflow-y: auto;
  width: 100%;
  margin: 0;
  margin-top: 107px;
  height: 1111px;
  padding-left: 20px;
  padding-right: 20px;
  
}

.social-media-input-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.social-media-input-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.social-media-select {
  width: 150px;
  margin-right: 10px;
}

.social-media-input {
  flex-grow: 1;
  margin-right: 10px;
}

.social-media-link-button {
  background-color: #ccc;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  margin-left: 10px;
  align-self: flex-start;
}

.social-media-link-button.add {
  background-color: green;
  color: white;
}

.social-media-link-button.remove {
  background-color: red;
  color: white;
}

.forms-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.forms-text {
  font-family: Baumans;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
}

.form-container {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  gap: 15px;
}

.form-left {
  margin-top: 25px;
  width: 60%;
  overflow-y: auto;
  max-height: 73vh;
}
/* Scrollbar styles for the left form */
.form-left {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 transparent; /* For Firefox */
  
}

.form-left::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

/* Track */
.form-left::-webkit-scrollbar-track {
  background: transparent; /* Track color */
}

/* Handle */
.form-left::-webkit-scrollbar-thumb {
  background-color: #888; /* Handle color */
  border-radius: 5px; /* Rounded corners */
}

/* Handle on hover */
.form-left::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}


.form-right {
  width: 40%;
  height: auto;
  display: flex;
  justify-content: center;
  overflow-y: hidden;
}

.form-left {
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.custom-sku-id{
  width: 50%;
  padding: 0px;
  box-sizing: border-box;
  margin-bottom: 10px;
  border: none;
  border-bottom: 0.5px solid #0956cc;
  background-color: transparent;
  outline: none;
  font-size: 15px;
}
.custom-sku-id::placeholder {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
  opacity: 0.5;
}
.custom-input,
.custom-textarea {
  width: 100%;
  padding: 10px 0 0 0;
  box-sizing: border-box;
  margin-bottom: 10px;
  border: none;
  border-bottom: 0.5px solid #0956cc;
  background-color: transparent;
  outline: none;
font-size: 15px !important;
}

.custom-input {
  padding-bottom: 5px;
}

.custom-textarea {
  margin-top: 5px;
  font-family: Arial, sans-serif;
  font-size: 15px;
  padding-bottom: 20px;
}

.custom-input::placeholder {
  font-family: Roboto;
  font-size: 32px;
  font-weight: 400;
  line-height: 37.5px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
  opacity: 0.5;
}

.custom-textarea::placeholder {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
  opacity: 0.5;
}

.custom-input:focus,
.custom-textarea:focus, .custom-sku-id:focus {
  border-bottom: 0.5px solid #0956cc;
}

.form-custom-dropdown {
  position: relative;
  user-select: none;
  width: 40%;
  margin-top: 5px;
}

.form-dropdown-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 0;
  border-bottom: 0.5px solid #0956cc;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.02em;
  color: #54555c;
  opacity: 50%;
}

.form-down-arrow {
  width: 10px;
  height: 6px;
  margin-right: 5px;
}

.form-dropdown-list {
  position: absolute;
  max-height: 300px; /* Set the desired maximum height */
  overflow-y: auto;
  width: 100%;
  z-index: 10;
  background-color: #f0f5fc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.form-dropdown-item {
  color: black;
  padding: 10px 13px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
}

.form-dropdown-item:last-child {
  border-bottom: none;
}

.form-dropdown-item:hover {
  background-color: #E2EEFF;
}

.price-and-checkboxes {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price-and-checkboxes p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
}


.form-price-discount {
  display: flex;
  width: 100%;
  gap: 15px;
}

.input-container {
  position: relative;
  width: 30%;
}

.currency-symbol {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  color: #000;
  pointer-events: none;
  font-size: 18px;
  padding-bottom: 4px;
}

.currency-input {
  width: 100%;
  padding: 8px 8px 8px 16px;
  border: none;
  border-bottom: 0.5px solid #0956cc;
  outline: none;
  background-color: transparent;
}

.currency-input::placeholder {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
  opacity: 0.5;
}
.form-images-text{
  margin-bottom: 10px;
}
.form-images-text p{
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
  margin-bottom: 0px;
}
.form-images-text span{
  font-size: 12px;
  font-weight: 300;
  text-align: left;
  color: #54555c;
}
.warning-product-texts{
  font-size: 12px;
  font-weight: 300;
  text-align: left;
  color: #54555c;
}

.limiter-texts{
  font-size: 12px;
  font-weight: 300;
  text-align: left;
  color: #54555c;
}

.form-images-upload{
  max-width: 130px;
}
.file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-label {
  display: flex;
  align-items: center; /* Align items vertically */
  gap: 10px; /* Add space between label and images */
  background-color: #e2eeff; /* Keep existing background color */
  padding: 60px 60px; /* Keep existing padding */
  border-radius: 8px;
}

.file-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #b2cef7;
  color: #0956cc;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 28.13px;
  letter-spacing: 0.02em;
  text-align: center;
}

.keybenefit-and-checkboxes {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.keybenefit-and-checkboxes p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
  margin-bottom: 5px;
}
.keybenefit-input-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
}

.keybenefit-input {
  flex-grow: 1;
  border: none;
  border-bottom: 0.5px solid #0956cc;
  outline: none;
  background-color: transparent;
  padding-bottom: 10px;
  
}

.keybenefit-signs-container {
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  height: 100%;
}

.keybenefit-signs-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0956CC;
  width: 18px;
  height: 18px;
  margin-left: 8px;
  margin-bottom: 10px;
  border-radius: 2px;
}

.keybenefit-add-icon, .keybenefit-minus-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0956CC;
  font-size: 12px;
  user-select: none;
  position: relative;
  bottom: 1px;
  left: 0.5px;
}

.delete-keybenefit {
  display: none;
}
.keybenefit-input::placeholder {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
  opacity: 0.5;
}

.video-links-checkboxes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 10px; */
}

.video-links-checkboxes p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
}

.video-link-input-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
}

.video-link-input {
  width: 100%;
  border: none;
  border-bottom: 0.5px solid #0956cc;
  outline: none;
  background-color: transparent;
  padding-bottom: 10px;
}

.video-link-signs-container {
  position: absolute;
  right: 5px;
  display: flex;
  align-items: center;
}

.video-link-signs-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0956CC;
  width: 18px;
  height: 18px;
  margin-left: 8px;
  border-radius: 2px;
  margin-bottom: 10px;
}

.video-link-add-icon, .video-link-minus-icon {
  cursor: pointer;
  color: #0956CC;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  bottom: 1px;
  left: 0.5px;
}

.video-link-input::placeholder {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
  opacity: 0.5;
}

.brand-story-checkboxes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.brand-story-checkboxes p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
}

.brand-story-textarea {
  width: 100%;
  padding: 0px 0 0 0;
  box-sizing: border-box;
  border: none;
  border-bottom: 0.5px solid #0956cc;
  background-color: transparent;
  outline: none;
  font-family: Arial, sans-serif;
  font-size: 15px;
}

.brand-story-textarea:focus {
  border-bottom: 0.5px solid #0956cc;
}

.brand-story-textarea::placeholder {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
  opacity: 0.5;
}
.brand-mission-checkboxes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.brand-mission-checkboxes p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
}

.brand-mission-textarea {
  width: 100%;
  padding: 0px 0 0 0;
  box-sizing: border-box;
  border: none;
  border-bottom: 0.5px solid #0956cc;
  background-color: transparent;
  outline: none;
  font-family: Arial, sans-serif; /* Specify the font you want to use */
font-size: 15px;

}

.brand-mission-textarea:focus {
  border-bottom: 0.5px solid #0956cc;
}

.brand-mission-textarea::placeholder {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
  opacity: 0.5;
}

.form-submit-button {
  display: flex;
  gap: 12px; /* Space between buttons */
  margin-bottom: 30px;
  margin-top: 20px;
}

.submit-button, .cancel-button {
  padding: 9px 20px;
  height: 35px;
  border-radius: 24px;
  color: #FFFFFF;
  font-size: 16px;
  border: none; 
  cursor: pointer;
}

.submit-button {
  background: linear-gradient(92.49deg, #0956CC 0%, #1A4E9F 100%);
  box-shadow: 4px 4px 16px 0px #FFFFFF33 inset, -4px -4px 16px 0px #00000040 inset;
}

.cancel-button {
  background: transparent;
  border: 1px solid;
  /* border-image-source: linear-gradient(109.89deg, #0956CC 1.29%, #052B66 99.41%);
  box-shadow: 4px 4px 16px 0px #FFFFFF33 inset, -4px -4px 16px 0px #00000040 inset; */
  color: #0956CC;
}

.checkbox-label{
  font-family: Roboto;
font-size: 16px;
font-weight: 400;
line-height: 18.75px;
letter-spacing: 0.02em;
text-align: left;
color: #54555C;
}

.form-checkbox{
  border-color: #0956CC;
  border: 1px solid #0956CC;
}
.checkbox-container{
  margin-left: 5px;
}

.color-picker p{
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
}

.customize-form-text{
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
  text-decoration: underline;
}

.quantity-header{
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
}
.quantity-section {
  display: flex;
  flex-direction: column;
}

.quantity-input-container {
  display: flex;
  align-items: center;
}

.quantity-input {
  width: 35%;
  padding: 8px 8px 8px 16px;
  margin-right: 10px;
  box-sizing: border-box;
  border: none;
  border-bottom: 0.5px solid #0956cc;
  background-color: transparent;
  outline: none;
}

.quantity-select {
  padding: 8px;
  border: none;
  border-bottom: 0.5px solid #0956cc;
  background-color: transparent;
  outline: none;
}

.nutrition-header {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
}

.nutrition-section {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.nutrition-amount-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}

.nutrition-amount-input {
  width: 15%;
  padding: 8px 4px;
  margin-right: 10px;
  box-sizing: border-box;
  border: none;
  border-bottom: 0.5px solid #0956cc;
  background-color: transparent;
  outline: none;
}

.nutrition-unit-select {
  padding: 8px;
  border: none;
  border-bottom: 0.5px solid #0956cc;
  background-color: transparent;
  outline: none;
}

.nutrition-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.nutrition-name-input {
  width: 50%;
  padding: 8px 16px;
  margin-right: 10px;
  box-sizing: border-box;
  border: none;
  border-bottom: 0.5px solid #0956cc;
  background-color: transparent;
  outline: none;
}

.nutrition-value-input {
  width: 15%;
  box-sizing: border-box;
  border: none;
  border-bottom: 0.5px solid #0956cc;
  background-color: transparent;
  outline: none;
}



.add-nutrition-item-button, .remove-nutrition-item-button {
  cursor: pointer;
  color: #0956CC;
  font-size: 12px;
  user-select: none;
  position: relative;
  bottom: 1px;
  margin-left: 10px;
border: 1px solid #0956CC;
padding: 2px 6px 3px 6px;
border-radius: 2px;
display: flex;
align-items: center;
justify-content: center;
}

.ingredient-header{
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
}

.form-certificate-text p{
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
}
.direction-to-use-text p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
}

.image-container {
  width: 50px;
  height: 50px;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: auto;
}

.textarea-container {
  margin-top: 20px;
}

.direction-use-textarea {
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  border: none;
  border-bottom: 0.5px solid #0956cc;
  background-color: transparent;
  outline: none;
  font-family: Arial, sans-serif;
  font-size: 15px;
}

.direction-button-container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.add-direction-item-button, .remove-direction-item-button {
  cursor: pointer;
  color: #0956CC;
  font-size: 12px;
  user-select: none;
  border: 1px solid #0956CC;
  border-radius: 2px;
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin-bottom: 10px;
}
.remove-direction-item-button{
  margin-right: 8px;
}

.direction-to-use-section{
  margin-top: 20px;
}
/* Common Styles */
.sustainability-image-container {
  width: 50px;
  height: 50px;
  overflow: hidden;
}

.sustainability-image-container img {
  width: 100%;
  height: auto;
}

.textarea-container {
  margin-top: 20px;
}

 .sustainability-textarea {
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  border: none;
  border-bottom: 0.5px solid #0956cc;
  background-color: transparent;
  outline: none;
  font-family: Arial, sans-serif;
  font-size: 15px;
  margin-top: 10px;
}

.sustainability-button-container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}


.sustainability-text p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
}
.social-media-header{
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
}

.social-media-input-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

.social-media-input-wrapper {
  display: flex;
  align-items: center;
}

.social-media-input {
  width: 90%;
  padding: 8px 4px;
  margin-right: 10px;
  box-sizing: border-box;
  border: none;
  border-bottom: 0.5px solid #0956cc;
  background-color: transparent;
  outline: none;
}
.social-media-input::placeholder{
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
  opacity: 0.5;
}

.social-icon {
  width: 26px;
  height: 26px;
  margin-left: 10px;
}

.form-error-text{
  color: red;
  font-size: 10px;
}






.greenCopy1 {
  width: 47px;
  color: aliceblue;
  flex: 1;
  position: relative;
  max-height: 100%;
  object-fit: cover;
}
.powerSource {
  align-self: stretch;
  display: flex;
  color: aliceblue;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-lgi) 0 0;
}
.footerInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.socialMediaContainer {
  display: flex;
  gap: 10px;
}

.socialMediaLink {
  display: inline-block;
}

.socialMediaIcon {
  width: 18px;
  height: 18px;
}
.privacyPolicy,
.returnPolicy {
  position: relative;
  font-size: 10px;
  letter-spacing: 0.01em;
}
.returnPolicy {
  padding-right: var(--padding-10xs);
}
.privacyPolicyContactUs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
  color: aliceblue;
}
.aboutUs {
  color: aliceblue;
  padding-right: var(--padding-3xs);
}
.aboutUs,
.contactUs {
  color: aliceblue;
  position: relative;
  letter-spacing: 0.01em;
}
.privacyPolicyContactUs1 {
  display: flex;
  color: aliceblue;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.powerSourceParent {
  align-self: stretch;
  color: aliceblue;
  flex: 1;
  color: aliceblue;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--padding-base);
}
.dividerLine {
  align-self: stretch;
  height: 1px;
  color: aliceblue;
  position: relative;
  border-top: 1px solid var(--color-white);
  box-sizing: border-box;
  opacity: 0.2;
}
.poweredByArvo {
  width: 164px;
  font-size: 7px;
  color: aliceblue;
  position: relative;
  letter-spacing: 0.01em;
  display: inline-block;
  padding-right: var(--padding-xl);
}
.footerInfo,
.frameParent {
  align-self: stretch;
  display: flex;
  color: aliceblue;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-base);
}
.frameParent {
  position: relative;
  height: 127px;
  color: aliceblue;
  
  background-color: rgb(32, 30, 30);
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-xs) 0 var(--padding-base);
  box-sizing: border-box;
  gap: var(--gap-sm);
  text-align: left;
  font-size: var(--font-size-xs);
  color: var(--color-white);
  font-family: var(--font-mulish);
}


/* Mobile Styles */
@media (max-width: 768px) {
  .form-container {
    flex-direction: column;
    gap: 0;
  
  }

  .forms-header{
    margin-top: 80px;
  }

  .form-left,
  .form-right {
    width: 100%;
  }



  .input-container {
    width: 100%;
  }

  .currency-input {
    padding-left: 30px;
  }

  .form-price-discount {
    flex-direction: column;
  }

  .quantity-input-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .quantity-input {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .social-media-select {
    width: 100%;
    margin-right: 0;
  }

  .direction-button-container {
    justify-content: space-between;
  }

  .remove-direction-item-button {
    margin-right: 0;
  }

  .image-container img,
  .sustainability-image-container img {
    width: 100%;
  }

  .form-custom-dropdown {
    width: 100%;
  }

  .nutrition-name-input {
    width: 60%;
  }

  .nutrition-value-input {
    width: 25%;
  }

  .add-nutrition-item-button,
  .remove-nutrition-item-button {
    padding: 2px 6px;
  }
}

.custom-part-check{
  width: 50%;
  padding: 0px;
  box-sizing: border-box;
  margin-bottom: 10px;
  border: none;
  border-bottom: 0.5px solid #0956cc;
  background-color: transparent;
  outline: none;
  font-size: 15px;
}
.custom-part-check::placeholder {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
  opacity: 0.5;
}

.custom-tick-part-check{
  margin-right: 20px;
}

.question-tick-box{
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
}

.morepen-form{
  position: relative;
  user-select: none;
  width: 40%;
  margin-top: 5px;
}

.morepen_input::placeholder {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.02em;
  text-align: left;
  color: #54555c;
  opacity: 0.5;
}

.morepen_input{

  width: 35%;
  padding: 8px 8px 8px 16px;
  margin-right: 10px;
  box-sizing: border-box;
  border: none;
  border-bottom: 0.5px solid #0956cc;
  background-color: transparent;
  outline: none;
}