.notification-container {
  position: relative;
}

.icon-circle {
  position: relative;
  cursor: pointer;
  z-index: 9;
}

.notification-badge {
  position: absolute;
  top: 2px;
  right: 6px;
  background-color: red;
  width: 18px;
  height: 18px;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-dropdown {
  position: absolute;
  top: 40px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  width: 250px; /* Adjust as needed */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10; /* Ensure dropdown is above other elements */
}

.notification-dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.notification-dropdown ul li {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
}

.notification-dropdown ul li:last-child {
  border-bottom: none;
}

.notification-dropdown p {
  margin: 7px;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
}

@media (max-width: 768px) {
  .notification-dropdown {
    width: 200px; /* Adjust width for mobile */
    top: 50px; /* Adjust top position for mobile */
    left: 50%;
    transform: translateX(-50%);
  }

  .notification-badge {
    width: 16px;
    height: 16px;
    font-size: 12px; /* Adjust font size for mobile */
  }

  .notification-dropdown ul li {
    padding: 8px; /* Adjust padding for mobile */
    font-size: 12px; /* Adjust font size for mobile */
  }

  .notification-dropdown p {
    font-size: 12px; /* Adjust font size for mobile */
  }
}

@media (max-width: 480px) {
  .notification-dropdown {
    width: 180px; /* Adjust width for mobile */
    top: 50px; /* Adjust top position for mobile */
    left: 50%;
    transform: translateX(-50%);
  }

  .notification-badge {
    width: 14px;
    height: 14px;
    font-size: 10px; /* Adjust font size for mobile */
  }

  .notification-dropdown ul li {
    padding: 6px; /* Adjust padding for mobile */
    font-size: 10px; /* Adjust font size for mobile */
  }

  .notification-dropdown p {
    font-size: 10px; /* Adjust font size for mobile */
  }
}
