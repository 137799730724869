
.customBox {
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  height: 600px; /* Adjust this if you also want to change the overall container's height */
  width: 100%;
}

.titleContainer h4 {
  margin: 0;
  white-space: nowrap;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.235;
}


.chartContainer {
  width: 100%; /* Adjust width as needed */
  height: 500px; /* Adjust height as needed */
  margin: 0; /* Center the chart container */
  overflow: visible;
}


