/* Base styles */
.sidebar {
  width: 20%;
  background-color: #031d45;
  height: 100vh;
  overflow-y: hidden;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0;
  top: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 999999;
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar-logo {
  height: 150px;
  width: 150px;
  border-radius: 50%;
}

.sidebar-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  margin: 10px;
  border-radius: 50%;
}

.sidebar-logo-container img,
.user-container img {
  max-width: 100%;
  display: block;
  border-radius: 50%;
}

.user-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 12px;
}

.user-image {
  border-radius: 50%;
  height: 68px;
  width: 68px;
}

.user-dropdown {
  width: 100%;
  padding: 8px 0px 8px 12px;
  color: white;
  text-align: left;
  font-family: "Roboto", sans-serif;
  border: none;
  border-radius: 24px;
  background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
  box-shadow: 4px 4px 16px 0px #ffffff33 inset,
    -4px -4px 16px 0px #00000040 inset, 0px 4px 4px 0px #00000040;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.02em;
}

.product-dropdown {
  width: 68%;
  position: relative;
}

.sidebar-line {
  height: 2px;
  width: 100%;
  background-color: #1d365b;
}

.sidebar-product-dropdown {
  position: relative;
  display: inline-block;
  width: 68%;
}

.sidebar-update-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(92.49deg, #0956cc 0%, #1a4e9f 100%);
  box-shadow: 4px 4px 16px 0px #ffffff33 inset,
    -4px -4px 16px 0px #00000040 inset;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 24px;
  font-family: "Roboto", sans-serif;
  width: 100%;
  gap: 12px;
}

.sidebar-dropdown-icon {
  width: 12px;
}

.sidebar-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f0f5fc;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
  border-radius: 7px;
  text-align: center;
}

.sidebar-product-dropdown
  .sidebar-update-button:focus
  + .sidebar-dropdown-content,
.sidebar-product-dropdown
  .sidebar-update-button:active
  + .sidebar-dropdown-content,
.sidebar-product-dropdown:hover .sidebar-dropdown-content {
  display: block;
}

.sidebar-dropdown-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.sidebar-dropdown-item:hover {
  background-color: #e2eeff;
  border-radius: 7px;
}

.sidebar-bottom {
  position: absolute;
  bottom: 150px;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 10px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.free-tier-label {
  text-align: center;
  padding: 10px 0;
  background-color: #7f21f4;
  color: white;
  font-size: 12px;
  border-radius: 10px;
  height: 35px;
  width: 100px;
}

.free-tier-text {
  color: white;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
}

.sidebar-bottom-arvo-logo {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 10px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.arvo-text {
  height: 14px;
  margin-right: 4px;
}

.arvo-logo-brand {
  height: 13px;
}

.sidebar-bottom-arvo-logo span {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 14.06px;
  letter-spacing: 0.02em;
  text-align: left;
  color: white;
  margin-right: 4px;
}

.nav-logout-container {
  display: flex;
  align-items: center;
  gap: 13px;
  position: relative;
  left: -10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.nav-logout-image-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #e2eeff;
}

.nav-logout-image-circle img {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.nav-logout-text {
  font-size: 16px;
  color: white;
  font-family: Roboto;
}

.powered-by {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.version-info {
  font-size: 8px;
  color: white;
  margin-top: 5px;
}

/* Sidebar arrow */
.sidebar-arrow {
  position: absolute;
  display: none;
  top: 50%;
  right: -30px;
  transform: translateY(-50%) rotate(180deg);
  background-color: #031d45;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: -20px;
}

/* Media queries for tablet */
@media (max-width: 768px) {
  .sidebar {
    width: 60%;
    transform: translateX(-100%);
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .sidebar-logo {
    height: 100px;
    width: 100px;
  }

  .sidebar-logo-container {
    margin: 10px 0;
  }

  .user-container {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .user-image {
    height: 50px;
    width: 50px;
  }

  .sidebar-update-button {
    font-size: 14px;
    padding: 8px 16px;
    gap: 10px;
  }

  .free-tier-label {
    font-size: 10px;
    height: 30px;
    width: 80px;
  }

  .sidebar-bottom {
    margin-bottom: 70px;
  }

  .sidebar-bottom-arvo-logo span {
    font-size: 10px;
  }

  .nav-logout-container {
    gap: 10px;
    left: -5px;
    margin-bottom: 5px;
  }

  .nav-logout-image-circle {
    width: 30px;
    height: 30px;
  }

  .nav-logout-image-circle img {
    width: 20px;
    height: 20px;
  }

  .nav-logout-text {
    font-size: 14px;
  }
  .sidebar-arrow {
    right: 5px;
    width: 35px;
    transform: translateY(-50%) rotate(180deg);
    height: 35px;

  }


}

@media (max-width: 480px) {
  .sidebar {
    width: 80%;
  }

  .sidebar-update-button {
    font-size: 12px;
    padding: 6px 12px;
    gap: 8px;
  }

  .free-tier-label {
    font-size: 8px;
    height: 35px;
    width: 70px;
  }

  .sidebar-bottom-arvo-logo span {
    font-size: 8px;
  }

  .nav-logout-text {
    font-size: 12px;
  }
  .sidebar-arrow {
    right: -5px;
    width: 35px;
    transform: translateY(-50%);
    height: 35px;

  }


.sidebar-arrow svg {
  transform: rotate(180deg) ; /* Rotate the icon itself, if needed */
}
}

/* Sidebar toggle button */
.sidebar-toggle {
  display: none;
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 99999;
  background-color: #031d45;
  color: white;
  border: none;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
}

/* Display the toggle button on small screens */
@media (max-width: 768px) {
  .sidebar-toggle {
    display: block;
  }
  .sidebar-arrow {
    display: block;
  }
}
