.dashboardContainer {
    display: flex;
    justify-content: space-around;
    
  }

 .headingLables{
    font-family: Baumans;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #54555c;
  }
  
  .box {
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    margin-left: 10px;
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    border: 0.2px solid rgb(192, 209, 236);
  }
  
  .topLeft {
    order: 1;
  }
  
  .topRight {
    order: 2;
  }
  
  .bigBox {
    display: flex;
    flex-wrap: wrap;
  }
  
  .leftBox,
  .rightBox {
    width: 50%; /* Adjust the width as needed */
  }
  
  .subBox {
    display: flex;
    background: linear-gradient(69.7deg, rgb(216, 81, 82) 40%, rgb(154, 27, 69) 100.1%);
    justify-content: space-between;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 10px;
    color: #fff;
    position: relative;
    padding: 10px;
    border: 0.2px solid rgb(192, 209, 236); 
  }
  .subBox1 {
    display: flex;
    background: linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147));
    justify-content: space-between;
    margin-bottom: 10px;
    color: #fff;
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    padding: 10px;
    border: 0.2px solid rgb(192, 209, 236); 
  }
  .subBox2 {
    display: flex;
    background: radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%);
    justify-content: space-between;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    padding: 10px;
    border: 0.2px solid rgb(192, 209, 236); 
  }
  .subBox3 {
    display: flex;
    background: linear-gradient(to right, rgb(5, 117, 230), rgb(2, 27, 121));
    justify-content: space-between;
    margin-bottom: 10px;
    background-color: #fff;
    color: #fff;
    border-radius: 10px;
    position: relative;
    padding: 10px;
    border: 0.2px solid rgb(192, 209, 236); 
  }
  
  .border {
    border: 1px solid #ddd;
  }
  
  .fullWidth {
    width: 100%;
  }
  