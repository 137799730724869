.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    background-color: #F2F8FF80;
    position: fixed;
    top: 0;
    z-index: 1000;
    height: 107px;
    padding: 0 20px;
    width: calc(100% - 20%);
  left: 20%;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  
  }
  