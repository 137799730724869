.superadmin-usermanager {
  background-color: #f2f7ff;
  overflow-y: auto;
  width: 100%;
  margin: 0;
  margin-top: 107px;
  height: 1111px;
  padding-left: 20px;
  padding-right: 20px;
}
.super-admin-last-updated {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
}

.superadmin-usermanager-header {
  font-family: Baumans;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
}