.brand-name {
    text-align: left;
  }
  
  .welcome {
    font-family: 'Baumans';
    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0.02em;
    display: inline;
    color:#54555C;
  }
  
  .username {
    font-family: 'Baumans';
    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0.02em;
    background: linear-gradient(109.89deg, #0956CC 1.29%, #052B66 99.41%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    /* text-fill-color: transparent; */
    display: inline;
  }
  
  .greeting {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #54555C;
  }
  