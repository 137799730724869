.file-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  max-width: 800px;
  margin: 20px auto;
  background-color: #f9f9f9;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.toggle-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.toggle-label {
  font-size: 16px;
  cursor: pointer;
}

.entry-sections {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

.manual-entry-section,
.batch-upload-section {
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  transition: all 0.3s ease;
}

.manual-entry-section.inactive,
.batch-upload-section.inactive {
  filter: grayscale(100%) blur(2px);
  pointer-events: none;
}

h3 {
  font-size: 20px;
  margin-bottom: 15px;
}

.manual-form,
.upload-form {
  display: flex;
  flex-direction: column;
}

.manual-label {
  margin-bottom: 10px;
}

.manual-input,
.file-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
}

.manual-button,
.upload-button {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #0956cc;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.manual-button:hover,
.upload-button:hover {
  background-color: #073b9c;
}

.status-message {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}

.loading-spinner {
  width: 18px;
  height: 18px;
  border: 3px solid #ffffff;
  border-top: 3px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  display: inline-block;
}
.separatorline {
  margin: 0 10px;
  font-size: 14px;
  color: gray;
  display: inline-block;
  text-align: center;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
