.customChartBox {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 600px;
  padding: 5px;
}


.chartTitle {
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.235;
  margin-bottom: 20px;
  z-index: 99999999;
}

.chart3dContainer {
  width: 100%;
  height: 500px;
}
