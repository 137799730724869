.info-container {
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;
}

.icon-circle, .image-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.2px solid #0956CC;
}

.img-info {
  height: 24px;
  width: 24px;
}

.profile-img-info {
  height: 100%;
  width: 100%;
}

.info-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999000;
  background-color: white;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  width: 300px;
  text-align: center;
}

.info-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-modal-content p {
  margin-bottom: 20px;
}

.info-modal-content button {
  margin: 5px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.info-modal-content button:first-of-type {
  background-color: #0956CC;
  color: white;
}

.info-modal-content button:last-of-type {
  background-color: #ccc;
  color: #333;
}

.info-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9989000;
}

/* Mobile responsiveness */
.credits-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.credits-button {
  width: auto;
  flex: 1;
  justify-content: flex-end;
}

/* Additional styles for mobile */
@media (max-width: 480px) {
  .credits-container {
    flex-direction: column;
    align-items: flex-start;
    height: 40px;
  }

  .credits-button {
    margin: 5px 0;
    font-size: 10px; /* Adjust font size for mobile */
    padding: 1px; /* Adjust padding for mobile */
  }

  .credits-button p {
    font-size: 10px; /* Reduce font size for Credits text */
  }

  .credits-button div {
    font-size: 10px; /* Reduce font size for credits value */
  }

  .credits-button svg {
    width: 20px; /* Reduce icon size */
    height: 20px; /* Reduce icon size */
  }
}
