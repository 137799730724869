.dashboard {
  background-color: #F2F7FF;
  overflow-y: auto;
  width: 100%;
  margin: 0;
  margin-top: 107px;
  height: 1111px;
  padding-left: 20px;
  padding-right: 20px;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-text {
  font-family: Baumans;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
}

.last-updated {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
}


.update-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(92.49deg, #0956CC 0%, #1A4E9F 100%);
  box-shadow: 4px 4px 16px 0px #FFFFFF33 inset, -4px -4px 16px 0px #00000040 inset;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  border-radius: 24px;
  gap: 12px;
  font-family: Roboto;
  line-height: 16px;
  letter-spacing: 0.02em;
}



.right-side-content {
  display: flex;
  align-items: center;
  gap: 16px;
}

.dailydropdown {
  position: relative;
  display: inline-block;
}

.dailydropdown-content {
  position: absolute;
  background-color: #f0f5fc;
  min-width: 116px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1000;
  right: 0;
  border-radius: 7px;
text-align: center;
}

.dailydropdown-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  z-index: 10000;
}

.dailydropdown-item:hover {
  background-color: #E2EEFF;
}

.pie-graph-container{
display: flex;
gap: 15px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(720deg);
  }
}

.reload-icon {
  cursor: pointer;
}

.rotate {
  animation: rotate 2s linear infinite; /* Apply the animation */
}
